import React from "react"
import "./rows.css"

class TopUser extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: this.props.name,
            header: this.props.header,
            serial_id: this.props.serial_id,
        }
        this.onExcludeClicked = this.props.onExcludeClicked
    }
    render(){
        return <div className="c_row_container row g-0">
            <div className="col-3">{this.state.serial_id}</div>
            <div className="col-3">{this.state.name}</div>
            {this.state.header?"":<div className="col-2"><button onClick={this.onExcludeClicked.bind(null, this.state.serial_id, this.state.name)} className="c_row_text_danger_button">Exclude</button></div>}
        </div>    
    }
}
export default TopUser