//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
import VerifiedUser from "../rows/VerifiedUser";
import RequestedProfile from "./RequestedProfile";

const VerifiedUsers = () => {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [verifiedUsers, setVerifiedUsers] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);

    async function getVerifiedUsers(){
        try{
            const verifiedusers = await axios.post(ApiHelper.userStatistics.getVerifiedUsers, ApiHelper.getAccessTokenHeaderConfig());
            setError(false);
            setVerifiedUsers(verifiedusers.data.data);
        }catch(error){
            setError(true);
            setMessage(error.response.data.error_message);
        }
    }
 
    function showVerifiedUsers(){
        if(error)return message;
        var rows = [];
        for(var i = 0; i < verifiedUsers.length; i++){
            var verifiedUser = verifiedUsers[i];
            rows.push(<VerifiedUser key={verifiedUser.user_id} header={false} user_id={verifiedUser.user_id} name={verifiedUser.user_name} selectProfileId={setSelectedProfileId} />);
        }
        return rows;
    }

    useEffect(() => {
        getVerifiedUsers();
    }, []);

    return <React.Fragment>
    <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Verified Users</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
            <VerifiedUser key={-1} header={true} user_id={<b>User ID</b>} name={<b>User Name</b>} />
                {showVerifiedUsers()}
            </div>
            <div className="c_microforms_footer row g-0">
                <div className="offset-6">
                    <span onClick={getVerifiedUsers} className="c_microforms_horizontalpaddedtextbutton">Refresh</span>
                </div>
            </div>
    </div>
    {selectedProfileId && <RequestedProfile key={selectedProfileId} profileId={selectedProfileId} showVerifiedUser={true} />}
    </React.Fragment>
}

export default VerifiedUsers;