import React from "react"
import { withRouter } from "react-router"
import SuspendedUsers from "../microforms/SuspendedUsers"

class SuspendedUsersPage extends React.Component {
    render(){
        return <div>
            <SuspendedUsers />
        </div>
    }
}
export default withRouter(SuspendedUsersPage)