import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import AdminPanel from "./AdminPanel";
import Login from "./Login";
import History from "./misc/History";
import ApiHelper from "./model/ApiHelper";
import SplashScreen from "./SplashScreen";
class App extends React.Component {
  // constructor(props){
  //     super(props)
  // }
  render() {
    return (
      <Router history={History}>
        <Switch>
          <Route path="/login" render={Login} />
          <Route path="/adminpanel" render={AdminPanel} />
          <Route path="/" exact render={SplashScreen} />
          <Route
            render={() => {
              return <h1>Oops! An Error Occured</h1>;
            }}
          />
        </Switch>
      </Router>
    );
  }
  componentDidMount() {
    if (ApiHelper.localStorage.isUserLoggedIn()) {
      if (History.location.pathname === "/") {
        if (
          JSON.stringify(
            ApiHelper.localStorage.getAdminData().authoritylevel
          ) === "4"
        ) {
          History.push("/adminpanel/post-ad");
        } else {
          History.push("/adminpanel/dashboard");
        }
      }
    } else {
      History.push("/login");
    }
  }
}

export default App;
