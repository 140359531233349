import React from "react"
import { withRouter } from "react-router"
import AddInterest from "../microforms/AddInterest"
import AddLifeStyleCategory from "../microforms/AddLifeStyleCategory"
import InterestsList from "../microforms/InterestsList"
import LifeStyleCategoriesList from "./../microforms/LifeStyleCategoriesList"

class Attributes extends React.Component {
    render(){
        return <div>
            <AddLifeStyleCategory/>
            <LifeStyleCategoriesList/>
            <AddInterest/>
            <InterestsList/>
        </div>
    }
}
export default withRouter(Attributes)