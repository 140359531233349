import React from "react"
import { withRouter } from "react-router"
import AdminList from "../microforms/AdminList"
import AddAdmin from "../microforms/AddAdmin"

class Credentials extends React.Component {
    render(){
        return <div>
            <AddAdmin/>
            <AdminList/>
        </div>
    }
}
export default withRouter(Credentials)