import React from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import logo from "./assets/logo.png";
import axios from "axios";
import ApiHelper from "./model/ApiHelper";

var LoginFormContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 360px;
  margin: 20vh auto;
  padding: 6px;
  border: 1px solid;
  border-color: #1a237e;
  border-radius: 25px;
`;
var LoginFormLogo = styled.img`
  position: relative;
  text-align: center;
  width: 90px;
  margin: 0px auto;
`;
var LoginFormInput = styled.input`
  position: relative;
  text-align: left;
  width: 240px;
  max-width: 90%;
  padding: 15px 15px 9px 15px;
  margin: 6px auto;
  border: 0px;
  border-radius: 6px;
  font-size: 15px;
  background: #eceff1;
`;
var LoginFormButton = styled.button`
  position: relative;
  text-align: center;
  width: 180px;
  height: 45px;
  margin: 10px auto;
  border: 0px;
  font-size: 15px;
  border-radius: 6px;
  color: #ffffff;
  background: #1a237e;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: #212121;
  }
`;
var Span = styled.span`
  cursor: pointer;
  font-weight: bold;
  transition: 0.2s;
  &:hover {
    color: #1a237e;
  }
`;
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginform: {
        emailid: "",
        password: "",
        confirmpassword: "",
        otp: "",
        message: "",
      },
      formno: 0,
    };
  }
  render() {
    return (
      <div className="c_container">
        <LoginFormContainer>
          <LoginFormLogo src={logo} />
          <br />
          {(() => {
            if (this.state.formno === 0) {
              return (
                <React.Fragment>
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.emailid}
                    type="email"
                    name="emailid"
                    placeholder="Email ID"
                  />
                  <br />
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.password}
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                  <br />
                  <LoginFormButton onClick={this.onLoginClicked.bind(this)}>
                    Submit
                  </LoginFormButton>
                  <br />
                  {this.state.loginform.message.length > 0 ? (
                    <span className="text-danger">
                      {this.state.loginform.message}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  Forgot Password?{" "}
                  <Span onClick={this.setForm.bind(this, 1)}>Click Here</Span>
                </React.Fragment>
              );
            } else if (this.state.formno === 1) {
              return (
                <React.Fragment>
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.emailid}
                    type="email"
                    name="emailid"
                    placeholder="Email ID"
                  />
                  <br />
                  <LoginFormButton onClick={this.onSendOTPClicked.bind(this)}>
                    Send OTP
                  </LoginFormButton>
                  <br />
                  {this.state.loginform.message.length > 0 ? (
                    <span className="text-danger">
                      {this.state.loginform.message}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  <Span onClick={this.setForm.bind(this, 0)}>
                    Click Here
                  </Span>{" "}
                  to Login
                </React.Fragment>
              );
            } else if (this.state.formno === 2) {
              return (
                <React.Fragment>
                  An OTP has been sent on your registered email. Please enter
                  the OTP below
                  <LoginFormInput
                    value={this.state.loginform.emailid}
                    readOnly
                  />
                  <br />
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.otp}
                    type="number"
                    name="otp"
                    placeholder="One Time Password"
                  />
                  <br />
                  <LoginFormButton onClick={this.onVerifyOTPClicked.bind(this)}>
                    Verify
                  </LoginFormButton>
                  <br />
                  {this.state.loginform.message.length > 0 ? (
                    <span className="text-danger">
                      {this.state.loginform.message}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  <Span onClick={this.setForm.bind(this, 0)}>
                    Click Here
                  </Span>{" "}
                  to Login
                </React.Fragment>
              );
            } else if (this.state.formno === 3) {
              return (
                <React.Fragment>
                  Set New Password
                  <LoginFormInput
                    value={this.state.loginform.emailid}
                    readOnly
                  />
                  <br />
                  <LoginFormInput value={this.state.loginform.otp} readOnly />
                  <br />
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.password}
                    type="password"
                    name="password"
                    placeholder="New Password"
                  />
                  <br />
                  <LoginFormInput
                    onChange={this.loginFormHandler.bind(this)}
                    value={this.state.loginform.confirmpassword}
                    type="password"
                    name="confirmpassword"
                    placeholder="Confirm New Password"
                  />
                  <br />
                  <LoginFormButton
                    onClick={this.onchangePasswordClicked.bind(this)}
                  >
                    Update
                  </LoginFormButton>
                  <br />
                  {this.state.loginform.message.length > 0 ? (
                    <span className="text-danger">
                      {this.state.loginform.message}
                    </span>
                  ) : (
                    ""
                  )}
                  <br />
                  <Span onClick={this.setForm.bind(this, 0)}>
                    Click Here
                  </Span>{" "}
                  to Login
                </React.Fragment>
              );
            }
          })()}
        </LoginFormContainer>
      </div>
    );
  }
  setForm(formno) {
    const newstate = { ...this.state };
    newstate.formno = formno;
    this.setState(newstate);
  }
  loginFormHandler(event) {
    const newstate = { ...this.state };
    newstate.loginform[event.target.name] = event.target.value;
    this.setState(newstate);
  }
  async onchangePasswordClicked() {
    try {
      if (
        this.state.loginform.password !== this.state.loginform.confirmpassword
      ) {
        const newstate = { ...this.state };
        newstate.loginform.message = "Passwords Do Not Match";
        this.setState(newstate);
        return;
      }
      var updatepassword = await axios.post(
        ApiHelper.authentication.updatePasswordWithOTPAndEmail,
        {
          emailid: this.state.loginform.emailid,
          otp: this.state.loginform.otp,
          newpassword: this.state.loginform.password,
        }
      );
      var data = updatepassword.data;
      const newstate = { ...this.state };
      if (data.error === false) {
        alert("Password Updated");
        newstate.formno = 0;
        newstate.loginform.message = "";
      } else newstate.loginform.message = data.error_message;
      this.setState(newstate);
    } catch (error) {
      const newstate = { ...this.state };
      newstate.loginform.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
  async onVerifyOTPClicked() {
    try {
      var verifyotp = await axios.post(ApiHelper.authentication.verifyOTP, {
        emailid: this.state.loginform.emailid,
        otp: this.state.loginform.otp,
      });
      var data = verifyotp.data;
      const newstate = { ...this.state };
      if (data.error === false) newstate.formno = 3;
      else newstate.loginform.message = data.error_message;
      this.setState(newstate);
    } catch (error) {
      const newstate = { ...this.state };
      newstate.loginform.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
  async onSendOTPClicked() {
    try {
      var sendotp = await axios.post(ApiHelper.authentication.sendOTP, {
        emailid: this.state.loginform.emailid,
      });
      var data = sendotp.data;
      const newstate = { ...this.state };
      if (data.error === false) newstate.formno = 2;
      else newstate.loginform.message = data.error_message;
      this.setState(newstate);
    } catch (error) {
      const newstate = { ...this.state };
      newstate.loginform.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
  async onLoginClicked() {
    try {
      var login = await axios.post(ApiHelper.authentication.login, {
        emailid: this.state.loginform.emailid,
        password: this.state.loginform.password,
      });
      var data = login.data;
      ApiHelper.localStorage.setTokens(data.data.tokens);
      ApiHelper.localStorage.setAdminData(data.data.admindata);
      if (
        JSON.stringify(ApiHelper.localStorage.getAdminData().authoritylevel) ===
        "4"
      )
        this.props.history.push("/adminpanel/my-ads");
      else this.props.history.push("/adminpanel");
    } catch (error) {
      const newstate = { ...this.state };
      newstate.loginform.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
}
export default withRouter(Login);
