//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
import ExclusiveUser from "../rows/ExclusiveUser";
import ExclusiveProfile from "./ExclusiveProfile";

const ExclusiveUsers = () => {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState("Conscious Diet");
    const [selectedGender, setSelectedGender] = useState("all");
    const [rank, setRank] = useState("top");
    const [categories, setCategories] = useState([]);
    const [offset, setOffset] = useState(0);

    async function getUsers(){
        try{
            const usersData = await axios.post(ApiHelper.userStatistics.getUsersByCategory, {offset, category: selectedCategory, gender: selectedGender, rank}, ApiHelper.getAccessTokenHeaderConfig());
            setError(false);
            setUsers(usersData.data.data);
        }catch(error){
            setError(true);
            setMessage(error.response.data.error_message);
        }
    }

    const getCategories = async () => {
        try{
            const categories = await axios.post(ApiHelper.primaryInterest.getInterests, ApiHelper.getAccessTokenHeaderConfig());
            setError(false);
            setCategories(categories.data.data);
        }catch(error){
            setError(true);
            setMessage(error.response.data.error_message);
        }
    };
 
    function showUsers(){
        if(error)return message;
        var rows = [];
        for(var i = 0; i < users.length; i++){
            var user = users[i];
            rows.push(<ExclusiveUser key={user.user_id} header={false} user_id={user.user_id} name={user.user_name} verified={user.profile_verified} selectProfileId={setSelectedProfileId} excludeUser={excludeUser} />);
        }
        return rows;
    }

    async function excludeUser(userid, name) {
        const confirm = window.confirm(`Are you sure you want to remove ${name} from the exclusive junction?`);
        if(confirm) {
            await axios.post(ApiHelper.userDetails.excludeFromExclusiveUsers, {userid}, ApiHelper.getAccessTokenHeaderConfig());
            getUsers();
        }
    }

    async function onPreviousClicked() {
        setOffset(offset - 10);
    }

    async function onNextClicked() {
        setOffset(offset + 10);
    }

    function onReset() {
        setOffset(0);
    }
    
    useEffect(() => {
        getUsers();
        getCategories();
    }, [selectedCategory, selectedGender, rank, offset]);

    return <React.Fragment>
    <div className="c_microforms_container">
        <div className="dropdown p-0">
            <select value={selectedCategory} onChange={({target}) => {setSelectedCategory(target.value); setOffset(0)}}>
                {categories.map(category => <option value={category.interest}>{category.interest}</option>)}
            </select>
            <select value={selectedGender} onChange={({target}) => {setSelectedGender(target.value); setOffset(0)}}>
                <option value="all">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select>
            <select value={rank} onChange={({target}) => {setRank(target.value); setOffset(0)}}>
                <option value="top">Top</option>
                <option value="intermediate">Intermediate</option>
                <option value="beginner">Beginner</option>
            </select>
        </div>
        <div className="c_microforms_header row g-0">
            <div className="col-10 c_microforms_headertext">Users</div>
            <div className="col-2 c_microforms_headerbutton"></div>
        </div>
        <div className="c_microforms_main">
        <ExclusiveUser key={-1} header={true} user_id={<b>User ID</b>} name={<b>User Name</b>} />
            {showUsers()}
        </div>
        <div className="c_microforms_footer row g-0">
            <div className="col-6">Showing {offset} - {offset + users.length}</div>
            <div className="col-6">
                <span onClick={onPreviousClicked} className="c_microforms_horizontalpaddedtextbutton">Previous</span>
                <span onClick={onNextClicked} className="c_microforms_horizontalpaddedtextbutton">Next</span>
                <span onClick={onReset} className="c_microforms_horizontalpaddedtextbutton">Reset</span>
            </div>
        </div>
    </div>
    {selectedProfileId && <ExclusiveProfile key={selectedProfileId} profileId={selectedProfileId} showVerifiedUser={true} />}
    </React.Fragment>
}

export default ExclusiveUsers;