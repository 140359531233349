//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
import UserReport from "../rows/UserReport";
import ReportedProfile from "./ReportedProfile";

const UserReports = () => {
    const [error, setError] = useState("");
    const [userReports, setUserReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState({});
    const [selectedProfileId, setSelectedProfileId] = useState(null);

    async function getUserReports(){
        try{
            const userreports = await axios.post(ApiHelper.userReports.getUserReports, ApiHelper.getAccessTokenHeaderConfig());
            setUserReports(userreports.data.data);
        }catch(error){
            setError(error.response.data.error_message);
        }
    }

    async function suspendReportedUser(user_id, user_name){
        try{
            const confirmation = window.confirm("Are you sure you want to suspend " + user_name + "?");
            if(confirmation) {
                await axios.post(ApiHelper.userReports.suspendReportedUser, {user_id}, ApiHelper.getAccessTokenHeaderConfig());
                getUserReports();
                setSelectedProfileId(null);
            }
        } catch(error) {
            setError(error.response.data.error_message);
        }
    }

    async function dismissUserReport(report_id, user_name){
        try{
            const confirmation = window.confirm("Are you sure you want to dismiss the user report filed on " + user_name + "?");
            if(confirmation) {
                await axios.post(ApiHelper.userReports.dismissUserReport, {report_id}, ApiHelper.getAccessTokenHeaderConfig());
                getUserReports();
                setSelectedProfileId(null);
            }    
        } catch(error) {
            setError(error.response.data.error_message);
        }
    }

    function showUserReports(){
        if(userReports.length !== 0) {
            var rows = [];
            for(var i = 0; i < userReports.reported.length; i++){
                rows.push(<UserReport key={userReports.reported[i].report_id} report_id={userReports.reported[i].report_id} header={false} user_id={userReports.reported[i].reported_id} name={userReports.reported[i].user_name} reportedBy={userReports.reporter[i].user_name} selectProfileId={setSelectedProfileId} selectReport={setSelectedReport} suspendReportedUser={suspendReportedUser} dismissUserReport={dismissUserReport} />);
            }
            return rows;
        }
    }  

    useEffect(() => {
        getUserReports();
    }, []);

    return <React.Fragment>
    <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Reported Users</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            {error && <div className="ms-4" style={{color: "red"}}>{error}</div>}
            <div className="c_microforms_main">
            <UserReport key={-1} header={true} user_id={<b>User ID</b>} name={<b>User Name</b>} reportedBy={<b>Reported By</b>} />
                {showUserReports()}
            </div>
            <div className="c_microforms_footer row g-0">
                <div className="offset-6">
                    <span onClick={getUserReports} className="c_microforms_horizontalpaddedtextbutton">Refresh</span>
                </div>
            </div>
        {error && <div className="ms-4" style={{color: "red"}}>{error}</div>}
    </div>
    {selectedProfileId && <ReportedProfile key={selectedProfileId} error={error} profileId={selectedProfileId} reportId={selectedReport} suspendReportedUser={suspendReportedUser} dismissUserReport={dismissUserReport} />}
    </React.Fragment>
}

export default UserReports;