import React from "react"
import { withRouter } from "react-router"
import ExclusiveUsers from "../microforms/ExclusiveUsers"

class ExclusiveUsersPage extends React.Component {
    render(){
        return <div>
            <ExclusiveUsers/>
        </div>
    }
}
export default withRouter(ExclusiveUsersPage)