//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import "./charts.css";
import {Bar} from "react-chartjs-2";
import ApiHelper from "src/model/ApiHelper";


const UsersChart = () => {
    const [selected, setSelected] = useState("Daily");
    const [count, setCount] = useState({});
    const [loading, setLoading] = useState(true); 

    function handleChange(event) {
        setSelected(event.target.value);
        setLoading(true);
    }

    useEffect(() => {
        async function getUsers() {
            try{
                const maleCount = await axios.post(ApiHelper.userStatistics.getUsersByPeriod, {gender: "male", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const femaleCount = await axios.post(ApiHelper.userStatistics.getUsersByPeriod, {gender: "female", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = {males: maleCount.data.data, females: femaleCount.data.data};
                setCount(data);
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        getUsers();
    }, [selected]);
    
    return (
        <div className="chart-container mx-lg-4 mt-3 mb-5">
            <div className="dropdown p-0">
                <select value={selected} onChange={handleChange} name="frequency">
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 months</option>
                </select>
            </div>
        {!loading && <Bar 
            data={{
                labels: [selected + " Users Count"],
                datasets: [
                    {
                        label: selected + " Male Users on App",
                        backgroundColor: "#FE0000",
                        data: [count.males]
                    },
                    {
                        label: selected + " Female Users on App",
                        backgroundColor: "#FFF3CD",
                        data: [count.females]
                    }
                ]
            }}
            className="chart shadow-lg"
            width={500}
            height={500}
            options={{
                maintainAspectRatio: false, 
                responsive: false
            }}
        />}
        </div>
    );
};

export default UsersChart;