import React from "react"
import { withRouter } from "react-router"
import DailyActiveUsers from "../countblocks/DailyActiveUsers"
import DailyMessageExchange from "../countblocks/DailyMessageExchange"
import TotalMessagesSent from "../countblocks/TotalMessagesSent"
import TotalSamphiesSent from "../countblocks/TotalSamphiesSent"
import TotalLikesSent from "../countblocks/TotalLikesSent"
import TotalDislikesSent from "../countblocks/TotalDislikesSent"
import PercentOfVerifiedMaleUsers from "../countblocks/PercentOfVerifiedMaleUsers"
import PercentOfVerifiedFemaleUsers from "../countblocks/PercentOfVerifiedFemaleUsers"
import TotalVerifiedMaleUsers from "../countblocks/TotalVerifiedMaleUsers"
import TotalVerifiedFemaleUsers from "../countblocks/TotalVerifiedFemaleUsers"
import PercentOfMaleSamphionisters from "../countblocks/PercentOfMaleSamphionisters"
import PercentOfFemaleSamphionisters from "../countblocks/PercentOfFemaleSamphionisters"
import TotalMatches from "../countblocks/TotalMatches"
import "bootstrap/dist/css/bootstrap.min.css"
class Dashboard extends React.Component {
    render(){
        return <React.Fragment>
            <div>
                <DailyActiveUsers/>
                <DailyMessageExchange/>
                <TotalMessagesSent/>
                <TotalSamphiesSent/>
                <TotalLikesSent/>
                <TotalDislikesSent/>
                <TotalMatches/>
                <PercentOfVerifiedMaleUsers/>
                <PercentOfVerifiedFemaleUsers/>
                <TotalVerifiedMaleUsers/>
                <TotalVerifiedFemaleUsers/>
                <PercentOfMaleSamphionisters/>
                <PercentOfFemaleSamphionisters/>
            </div>
            <div className="row">

            </div>
            <div className="row">

            </div>
        </React.Fragment>
    }
}
export default withRouter(Dashboard)