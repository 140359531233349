import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "./countblocks.css"

class TotalPaidUsers extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            count: 0
        }
    }
    render(){
        return <div className="c_countblock_container">
            <div className="c_countblock_main">
                <div className="c_countblock_header">Total Paid Users</div>
                <div className="c_countblock_content">{this.state.count}</div>
                <div className="c_countblock_footer"></div>
            </div>
        </div>
    }
    componentDidMount() {
        this.getDailyMessageExchange()
    }
    async getDailyMessageExchange(){
        try{
            var newstate = {...this.state}
            var dailyactiveusers = await axios.post(ApiHelper.userStatistics.getTotalPaidUsers, {}, ApiHelper.getAccessTokenHeaderConfig)
            newstate.count = dailyactiveusers.data.data
            this.setState(newstate)
        }catch(error){
            
        }
    }
}
export default TotalPaidUsers