import React from "react"
import { withRouter } from "react-router"
import VerificationRequests from "../microforms/VerificationRequests"

class VerificationRequestsPage extends React.Component {
    render(){
        return <div>
            <VerificationRequests/>
        </div>
    }
}
export default withRouter(VerificationRequestsPage)