import React from "react"
import { withRouter } from "react-router"
import AddBlogs from "../microforms/AddBlogs"
import BlogsList from "../microforms/BlogsList"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"

class Account extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error: false,
            message: "",
            category_name: "",
            categories: [],
            quotes: [],
            catName: "",
            image: "",
            titleName: "",
            blogName: "",
        }
    }
    
    componentDidMount() {
        this.getCategories()
        this.getBlogs()
    }

    getCategories = async () => {
        try {
            const categoriesResponse = await axios.post(ApiHelper.readablecontent.getAllCategories, { offset: 0 }, ApiHelper.getAccessTokenHeaderConfig())
            this.setState({ categories: categoriesResponse.data.data, catName: categoriesResponse.data.data.length ? categoriesResponse.data.data[0].category_name : '' })
        } catch (error) {
        }
    }

    getBlogs = async () => {
        try {
            const quotesResponse = await axios.post(ApiHelper.readablecontent.getAllBlogs, { offset: 0 }, ApiHelper.getAccessTokenHeaderConfig())
            this.setState({ quotes: quotesResponse.data.data })
        } catch (error) {
        }
    }

    onFileChange = (event) => {
        var newstate = {...this.state}
        newstate.image = event.target.files[0]
        this.setState(newstate)
    }

    onDeleteBlog = async (blog_id) => {
        try {
            await axios.post(ApiHelper.readablecontent.deleteBlog, {
                blog_id: blog_id
            }, ApiHelper.getAccessTokenHeaderConfig())
            this.getBlogs()
        } catch (error) {
        }
    }

 onAddCategoryClicked = async () => {
    // console.log(this.state)
    try{
        var newstate = {...this.state}
        const formdata = new FormData()
        formdata.append("images", this.state.image)
        var uploadimage = await axios.post(ApiHelper.appApi.uploadImage, formdata, {'content-type': 'multipart/form-data'})
        newstate.message = uploadimage.data.message
        if(uploadimage.data.status === 400){
            this.setState(newstate)
            return
        }
        var imageurl = uploadimage.data.data.image
        var createQuotes = await axios.post(ApiHelper.readablecontent.createBlogs, {
            category: this.state.catName,
            image: imageurl,
            title: this.state.titleName,
            blog_link: this.state.blogName
        }, ApiHelper.getAccessTokenHeaderConfig())
        newstate.message = createQuotes.data.error_message
        this.setState(newstate)
        this.getBlogs()
    }catch(error){
        console.log(error)
        newstate.error = true
        newstate.message = error.response.data.error_message
        this.setState(newstate)
    }    
}

onChangeCategoryName = (event) => {
    this.setState({
        catName: event.target.value
    })
}
onChangeHandlerTitle = (event) => {
    this.setState({
        titleName: event.target.value
    })
}
onChangeHandlerBlog = (event) => {
    this.setState({
        blogName: event.target.value
    })
}

    render(){
        return <div>
        <AddBlogs
        categories={this.state.categories}
        catName={this.state.catName}
        imageurl={this.state.imageurl}
        image={this.state.image}
        onFileChange={this.onFileChange}
        onChangeCategoryName={this.onChangeCategoryName}
        onAddCategoryClicked={this.onAddCategoryClicked}
        onChangeHandlerTitle={this.onChangeHandlerTitle}
        onChangeHandlerBlog={this.onChangeHandlerBlog}
        />
        <BlogsList
        quotes={this.state.quotes}
        onDeleteBlog={this.onDeleteBlog}
        />
    </div>
    }
}
export default withRouter(Account)