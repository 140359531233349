import React from "react"
import { withRouter } from "react-router"
import UpdateAccount from "../microforms/UpdateAccount"
import AccountDetails from "../microforms/AccountDetails"

class Account extends React.Component {
    render(){
        return <div>
        <AccountDetails/>
        <UpdateAccount/>
    </div>
    }
}
export default withRouter(Account)