import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
import TopUser from "../rows/TopUser"
class TopUsers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error:false,  
            message: "",
            topusers: []
        }
    }
    showTopUsers(){
        if(this.state.error)return this.state.message
        var rows = []
        for(var i = 0; i < this.state.topusers.length; i++){
            var topuser = this.state.topusers[i]
            rows.push(<TopUser key={topuser.interest_id} header={false} serial_id={topuser.user_id} name={topuser.user_name} onExcludeClicked={this.onExcludeClicked.bind(this)}/>)
        }
        return rows
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Top Users</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
            <TopUser key={-1} header={true} serial_id={<b>User ID</b>} name={<b>User Name</b>} onExcludeClicked={this.onExcludeClicked.bind(this)} />
                {this.showTopUsers()}
            </div>
            <div className="c_microforms_footer row g-0">
                <div className="col-6">Showing {this.state.offset} - {this.state.offset + this.state.topusers.length}</div>
                <div className="col-6">
                    <span onClick={this.onRefreshClicked.bind(this)} className="c_microforms_horizontalpaddedtextbutton">Refresh</span>
                </div>
            </div>
        </div>
    }
    onRefreshClicked(){
        const newstate = {...this.state}
        newstate.topusers = []
        this.setState(newstate, () => {
            this.getTopUsers()
        })
    }
    async onExcludeClicked(id, name){
        console.log(id)
        var confirmation = window.confirm("Exclude "+name+" from Top Users List?")
        if(confirmation){
            this.excludeFromTopUsers(id)
        }
    }
    async componentDidMount(){
        await this.getTopUsers()
    }
    async excludeFromTopUsers(id){
        try{
            var newstate = {...this.state}
            await axios.post(ApiHelper.userDetails.excludeFromTopUsers, {userid: id}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.message = ""
            newstate.topusers = []
            this.setState(newstate, () => {
                this.getTopUsers()
            })
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
    async getTopUsers(){
        try{
            var newstate = {...this.state}
            var primaryinterests = await axios.post(ApiHelper.userStatistics.getTopUsers, {}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.topusers = primaryinterests.data.data
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
}
export default TopUsers