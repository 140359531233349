//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
import User from "../rows/User";
import RequestedProfile from "./RequestedProfile";

const Users = () => {
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [users, setUsers] = useState([]);
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [offset, setOffset] = useState(0);
    const [verified, setVerified] = useState("no");
    const [samphionister, setSamphionister] = useState("no");
    const [gender, setGender] = useState("female");

    async function getUsers(){
        try{
            const usersData = await axios.post(ApiHelper.userStatistics.getUsersByOffset, {offset, verified, samphionister, gender}, ApiHelper.getAccessTokenHeaderConfig());
            setError(false);
            setUsers(usersData.data.data);
        }catch(error){
            setError(true);
            setMessage(error.response.data.error_message);
        }
    }
 
    function showUsers(){
        if(error)return message;
        var rows = [];
        for(var i = 0; i < users.length; i++){
            var user = users[i];
            rows.push(<User key={user.user_id} header={false} user_id={user.user_id} name={user.user_name} verified={user.profile_verified} samphionister={user.samphionister} selectProfileId={setSelectedProfileId} handleVerification={handleVerification} handleSamphionister={handleSamphionister} />);
        }
        return rows;
    }

    async function handleVerification({user_id, name, flag}) {
        const confirm = window.confirm(flag === "no" ? `Are you sure you want to unverify ${name}?` : `Are you sure you want to verify ${name}?`);
        if(confirm) {
            await axios.post(ApiHelper.userDetails.editVerified, {userid: user_id, flag}, ApiHelper.getAccessTokenHeaderConfig());
            getUsers();
        }
    }

    async function handleSamphionister({user_id, name, flag}) {
        const confirm = window.confirm(flag === "no" ? `Are you sure you want to remove samphionister from ${name}?` : `Are you sure you want to give samphionister to ${name}?`);
        if(confirm) {
            await axios.post(ApiHelper.userDetails.editSamphionister, {userid: user_id, flag}, ApiHelper.getAccessTokenHeaderConfig());
            getUsers();
        }
    }

    async function onPreviousClicked() {
        setOffset(offset - 10);
    }

    async function onNextClicked() {
        setOffset(offset + 10);
    }

    function onReset() {
        setOffset(0);
    }
    
    useEffect(() => {
        getUsers();
    }, [offset, verified, samphionister, gender]);

    return <React.Fragment>
    <div className="c_microforms_container">
        <div className="dropdown p-0">
            <select value={gender} onChange={({target}) => {setGender(target.value); setOffset(0)}}>
                <option value="all">All</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </select>
            <select value={verified} onChange={({target}) => {setVerified(target.value); setOffset(0)}}>
                <option value="no">Not Verified</option>
                <option value="yes">Verified</option>
            </select>
            <select value={samphionister} onChange={({target}) => {setSamphionister(target.value); setOffset(0)}}>
                <option value="no">Not Samphionister</option>
                <option value="yes">Samphionister</option>
            </select>
        </div>
        <div className="c_microforms_header row g-0">
            <div className="col-10 c_microforms_headertext">Users</div>
            <div className="col-2 c_microforms_headerbutton"></div>
        </div>
        <div className="c_microforms_main">
        <User key={-1} header={true} user_id={<b>User ID</b>} name={<b>User Name</b>} />
            {showUsers()}
        </div>
        <div className="c_microforms_footer row g-0">
            <div className="col-6">Showing {offset} - {offset + users.length}</div>
            <div className="col-6">
                <span onClick={onPreviousClicked} className="c_microforms_horizontalpaddedtextbutton">Previous</span>
                <span onClick={onNextClicked} className="c_microforms_horizontalpaddedtextbutton">Next</span>
                <span onClick={onReset} className="c_microforms_horizontalpaddedtextbutton">Reset</span>
            </div>
        </div>
    </div>
    {selectedProfileId && <RequestedProfile key={selectedProfileId} profileId={selectedProfileId} showVerifiedUser={true} />}
    </React.Fragment>
}

export default Users;