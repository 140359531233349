import React from "react";
import { Route, Switch, withRouter } from "react-router";
import ApiHelper from "./model/ApiHelper";
import styled from "styled-components";
import logo from "./assets/logo.png";
import Dashboard from "./components/pages/Dashboard";
import Statistics from "./components/pages/Statistics";
import Attributes from "./components/pages/Attributes";
import UsersPage from "./components/pages/UsersPage";
import ExclusiveUsersPage from "./components/pages/ExclusiveUsersPage";
import TopUsersPage from "./components/pages/TopUsersPage";
import VerifiedUsersPage from "./components/pages/VerifiedUsersPage";
import SuspendedUsersPage from "./components/pages/SuspendedUsersPage";
import VerificationRequestsPage from "./components/pages/VerificationRequestsPage";
import ReportsPage from "./components/pages/ReportsPage";
import Account from "./components/pages/Account";
import Credentials from "./components/pages/Credentials";
import Security from "./components/pages/Security";
import Categories from "./components/pages/Categories";
import Blog from "./components/pages/Blog";
import Poetry from "./components/pages/Poetry";
import Quotes from "./components/pages/Quotes";
import Facts from "./components/pages/Facts";
import AdForm from "./components/pages/AdForm";
import AllAds from "./components/pages/AllAds";
import MyAds from "./components/pages/MyAds";
import AdUpdateForm from "./components/pages/AdUpdateForm";

var SideBarContainer = styled.div`
  position: fixed;
  text-align: center;
  width: 200px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 1px 0px 1px #bdbdbd;
  z-index: 99;
`;
var SideBarHeader = styled.div`
  position: relative;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  padding: 10px;
  display: flexbox;
  align-items: center;
`;
var SideBarMain = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
`;
var SideBarSpan = styled.div`
  position: relative;
  width: 100%;
  height: 18px;
`;
var SideBarLogo = styled.img`
  position: relative;
  width: 30px;
`;
var SideBarAppName = styled.div`
  position: relative;
  color: #0c2feb;
`;
var SideBarElement = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 12px;
  padding: 6px 18px 6px 18px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    background: #bdbdbd;
  }
`;
var PanelContainer = styled.div`
  position: relative;
  text-align: left;
  width: 100%;
  min-height: 100vh;
  z-index: 0;
  padding-left: 200px;
  background: #fafafa;
`;
var A = styled.a`
  color: unset;
  text-decoration: none;
  &:hover {
    color: unset;
    text-decoration: none;
  }
`;
class AdminPanel extends React.Component {
  render() {
    return (
      <React.Fragment>
        <header className="c_header"></header>
        <SideBarContainer className="overflow-auto">
          <SideBarHeader>
            <SideBarLogo src={logo} />
            <SideBarAppName>Samphion</SideBarAppName>
          </SideBarHeader>
          <SideBarMain className="overflow-auto">
            {JSON.stringify(
              ApiHelper.localStorage.getAdminData().authoritylevel
            ) === "4" ? (
              <>
                <A href="/adminpanel/my-ads">
                  <SideBarElement>My Ads</SideBarElement>
                </A>
              </>
            ) : (
              <div className="overflow-auto">
                <A href="/adminpanel/dashboard">
                  <SideBarElement>Dashboard</SideBarElement>
                </A>
                <A href="/adminpanel/statistics">
                  <SideBarElement>Statistics</SideBarElement>
                </A>
                <A href="/adminpanel/attributes">
                  <SideBarElement>Attributes</SideBarElement>
                </A>
                <SideBarSpan />
                <A href="/adminpanel/users">
                  <SideBarElement>All Users</SideBarElement>
                </A>
                <A href="/adminpanel/exclusive-users">
                  <SideBarElement>Exclusive Users</SideBarElement>
                </A>
                <A href="/adminpanel/top-users">
                  <SideBarElement>Top Users</SideBarElement>
                </A>
                <A href="/adminpanel/verified-users">
                  <SideBarElement>Verified Users</SideBarElement>
                </A>
                <A href="/adminpanel/suspended-users">
                  <SideBarElement>Suspended Users</SideBarElement>
                </A>
                <SideBarSpan />
                {/* <A href="/adminpanel/verification-requests"><SideBarElement>Verification Requests</SideBarElement></A> */}
                <A href="/adminpanel/user-reports">
                  <SideBarElement>User Reports</SideBarElement>
                </A>
                <SideBarSpan />
                <A href="/adminpanel/categories">
                  <SideBarElement>Add Categories</SideBarElement>
                </A>
                <A href="/adminpanel/quotes">
                  <SideBarElement>Add Quotes</SideBarElement>
                </A>
                <A href="/adminpanel/facts">
                  <SideBarElement>Add Facts</SideBarElement>
                </A>
                <A href="/adminpanel/blog">
                  <SideBarElement>Add Blogs</SideBarElement>
                </A>
                <A href="/adminpanel/poetry">
                  <SideBarElement>Add Poetry</SideBarElement>
                </A>
                <SideBarSpan />

                <A href="/adminpanel/post-ad">
                  <SideBarElement>Post New Ad</SideBarElement>
                </A>
                <A href="/adminpanel/all-ads">
                  <SideBarElement>All Ads</SideBarElement>
                </A>
                <SideBarSpan />

                <A href="/adminpanel/account">
                  <SideBarElement>Account</SideBarElement>
                </A>
                <A href="/adminpanel/security">
                  <SideBarElement>Security</SideBarElement>
                </A>
                <A href="/adminpanel/credentials">
                  <SideBarElement>Credentials</SideBarElement>
                </A>
              </div>
            )}
            <SideBarElement onClick={this.onLogoutClicked.bind(this)}>
              Logout
            </SideBarElement>
          </SideBarMain>
        </SideBarContainer>
        <PanelContainer>
          {JSON.stringify(
            ApiHelper.localStorage.getAdminData().authoritylevel
          ) === "4" ? (
            <Switch>
              <Route path="/adminpanel/my-ads" exact render={MyAds} />
            </Switch>
          ) : (
            <Switch>
              <Route path="/adminpanel/dashboard" exact render={Dashboard} />
              <Route path="/adminpanel/statistics" exact render={Statistics} />
              <Route path="/adminpanel/attributes" exact render={Attributes} />
              <Route path="/adminpanel/users" exact render={UsersPage} />
              <Route
                path="/adminpanel/exclusive-users"
                exact
                render={ExclusiveUsersPage}
              />
              <Route path="/adminpanel/top-users" exact render={TopUsersPage} />
              <Route
                path="/adminpanel/verified-users"
                exact
                render={VerifiedUsersPage}
              />
              <Route
                path="/adminpanel/suspended-users"
                exact
                render={SuspendedUsersPage}
              />
              <Route
                path="/adminpanel/verification-requests"
                exact
                render={VerificationRequestsPage}
              />
              <Route
                path="/adminpanel/user-reports"
                exact
                render={ReportsPage}
              />
              <Route path="/adminpanel/post-ad" exact render={AdForm} />
              <Route path="/adminpanel/all-ads" exact render={AllAds} />
              <Route path="/adminpanel/update-ad" exact render={AdUpdateForm} />
              <Route path="/adminpanel/account" exact render={Account} />
              <Route path="/adminpanel/security" exact render={Security} />
              <Route
                path="/adminpanel/credentials"
                exact
                render={Credentials}
              />
              <Route path="/adminpanel" exact render={Dashboard} />
              <Route path="/adminpanel/categories" exact render={Categories} />
              <Route path="/adminpanel/blog" exact render={Blog} />
              <Route path="/adminpanel/poetry" exact render={Poetry} />
              <Route path="/adminpanel/quotes" exact render={Quotes} />
              <Route path="/adminpanel/facts" exact render={Facts} />
            </Switch>
          )}
        </PanelContainer>
      </React.Fragment>
    );
  }
  onLogoutClicked() {
    var confirmed = window.confirm("Logout?");
    if (confirmed) {
      ApiHelper.localStorage.logout();
      this.props.history.push("/login");
    }
  }
  preCheck() {
    if (!ApiHelper.localStorage.isUserLoggedIn()) {
      ApiHelper.localStorage.logout();
      return this.props.history.push("/login");
    }
    var logindata = ApiHelper.localStorage.getLoginData();
    if (logindata.logintime + 28800 < new Date().getTime() / 1000) {
      ApiHelper.localStorage.logout();
      return this.props.history.push("/login");
    }
    console.log(
      "Session Time Left: " +
        parseInt(
          (new Date().getTime() / 1000 - logindata.logintime + 28800) / 60
        ) +
        " Minutes"
    );
  }
  componentDidMount() {
    this.preCheck();
  }
}

export default withRouter(AdminPanel);
