import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import moment from "moment";
import { withRouter, useHistory } from "react-router";
import IcDelete from "../../assets/ic_delete.png";
import IcEdit from "../../assets/ic_edit.png";
import "./switch.css";

const AllAdsPage = (props) => {
  const [allAds, setAllAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [adVisibility, setAdVisibility] = useState(true);

  async function getAllAds() {
    setAllAds([]);
    setIsLoading(true);
    var getAds = await axios.get(
      ApiHelper.ads.getAllAdsAdmin,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    setIsLoading(false);
    setAllAds(getAds.data.data);
  }

  async function deleteAd(ad_id) {
    var response = await axios.delete(
      ApiHelper.ads.deleteAd + `/${ad_id}`,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    if (response.data.error_message === "Error")
      alert("Error: " + JSON.stringify(response.data.data));
    else {
      alert(response.data.error_message);
      getAllAds();
    }
  }

  async function resumeAd(ad_id) {
    var response = await axios.get(
      ApiHelper.ads.resumeAd + `/${ad_id}`,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    if (response.data.error_message === "Error")
      alert("Error: " + JSON.stringify(response.data.data));
    else {
      alert(response.data.error_message);
      getAllAds();
    }
  }

  const checkVisibility = async () => {
    var response = await axios.get(
      ApiHelper.ads.checkAdsVisibility,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    if (response.data.error_message === "Error")
      alert("Error: " + JSON.stringify(response.data.data));
    else {
      setAdVisibility(response.data.data.visibility);
    }
  };

  const changeVisibility = async () => {
    adVisibility ? makeAdsInVisible() : makeAdsVisible();
  };

  const makeAdsVisible = async () => {
    var response = await axios.get(
      ApiHelper.ads.makeAdsVisible,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    if (response.data.error_message === "Error")
      alert("Error: " + JSON.stringify(response.data.data));
    else {
      setAdVisibility(true);
    }
  };

  const makeAdsInVisible = async () => {
    var response = await axios.get(
      ApiHelper.ads.makeAdsInVisible,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    if (response.data.error_message === "Error")
      alert("Error: " + JSON.stringify(response.data.data));
    else {
      setAdVisibility(false);
    }
  };

  useEffect(() => {
    getAllAds();
    checkVisibility();
  }, []);

  function showSpinner() {
    return (
      <div
        class="d-flex align-items-center justify-content-center"
        style={{ height: "350px" }}
      >
        <div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  function showNoAds() {
    return (
      <div>
        <h4>No Ads Yet</h4>
        <hr className="mb-4" />
      </div>
    );
  }

  function showAllAds() {
    return (
      <div>
        <h4>All Ads</h4>
        <div>
          <label class="switch">
            <input
              type="checkbox"
              checked={adVisibility}
              onClick={(e) => changeVisibility()}
            />

            <span class="slider round"></span>
          </label>
          {adVisibility ? (
            <h6>Stop Displaying Ads</h6>
          ) : (
            <h6>Start Displaying Ads</h6>
          )}
        </div>
        <hr className="mb-4" />
        <table class="table text-center">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Image</th>
              <th scope="col">Company Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Num of days</th>
              <th scope="col">Total Swipe ups</th>
              <th scope="col">Swipes Per day</th>
              <th scope="col">Remaining Swipe ups</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {allAds.map((ad) => (
              <tr style={{ backgroundColor: ad.deleted ? "#F0EDED" : "white" }}>
                <th class="align-middle text-center" scope="row">
                  {ad.ad_id}
                </th>
                <td class="align-middle text-center">
                  <img
                    src={ad.photographUrl}
                    alt={ad.ad_id}
                    style={{ width: "150px", height: "100px" }}
                  />
                </td>
                <td class="align-middle text-center">{ad.nameOfCompany}</td>
                <td class="align-middle text-center">
                  {moment(ad.startDate).format("MM/DD/YYYY")}
                </td>
                <td class="align-middle text-center">
                  {moment(ad.endDate).format("MM/DD/YYYY")}
                </td>
                <td class="align-middle text-center">{ad.totalNumberOfDays}</td>
                <td class="align-middle text-center">{ad.totalSwipeUps}</td>
                <td class="align-middle text-center">{ad.swipesPerDay}</td>
                <td class="align-middle text-center">
                  {ad.totalSwipesRemaining}
                </td>
                <td class="align-middle text-center">
                  {ad.deleted ? (
                    <>
                      <p>Ad Deleted</p>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm text-white"
                        onClick={() => resumeAd(ad.ad_id)}
                      >
                        Resume Ad
                      </button>
                      <button
                        type="button"
                        class="btn btn-info text-white btn-sm mr-1"
                        onClick={() => {
                          history.push({
                            pathname: "/adminpanel/update-ad",
                            state: { ad_id: ad.ad_id },
                          });
                        }}
                      >
                        <img src={IcEdit} alt="Edit" />
                      </button>
                    </>
                  ) : (
                    <div>
                      <button
                        type="button"
                        class="btn btn-info text-white btn-sm mr-1"
                        onClick={() => {
                          history.push({
                            pathname: "/adminpanel/update-ad",
                            state: { ad_id: ad.ad_id },
                          });
                        }}
                      >
                        <img src={IcEdit} alt="Edit" />
                      </button>

                      <button
                        type="button"
                        class="btn btn-danger btn-sm text-white"
                        onClick={() => deleteAd(ad.ad_id)}
                      >
                        <img src={IcDelete} alt="Delete" />
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function showAds() {
    return <div>{allAds.length == 0 ? showNoAds() : showAllAds()}</div>;
  }

  return (
    <div className="container p-4">{isLoading ? showSpinner() : showAds()}</div>
  );
};

class AllAds extends React.Component {
  render() {
    return (
      <div>
        <AllAdsPage />
      </div>
    );
  }
}

export default withRouter(AllAds);
