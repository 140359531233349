//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import "./charts.css";
import {Bubble} from "react-chartjs-2";
import ApiHelper from "src/model/ApiHelper";

const SwipesGrowthChart = () => {
    const [selected, setSelected] = useState("Daily");
    const [count, setCount] = useState({});
    const [growth, setGrowth] = useState({});
    const [loading, setLoading] = useState(true);

    async function getGrowth(request) {
        try{
            const response = await axios.post(request, ApiHelper.getAccessTokenHeaderConfig());
            const data = response.data.data;
            setGrowth({likes: data.likes[0], dislikes: data.dislikes[0], samphies: data.samphies[0]});
            setCount({likes: data.likes[1], dislikes: data.dislikes[1], samphies: data.samphies[1]});
            setLoading(false);
        }catch(error){
            return error;
        }
    }

    function handleChange(event) {
        setSelected(event.target.value);
        setLoading(true);
    }

    useEffect(() => {
        switch(selected) {
            case "Daily": 
                getGrowth(ApiHelper.swipes.getDailyGrowth);
                break;
            case "Weekly": 
                getGrowth(ApiHelper.swipes.getWeeklyGrowth);
                break;
            case "Monthly": 
                getGrowth(ApiHelper.swipes.getMonthlyGrowth);
                break;
            case "3 Months": 
                getGrowth(ApiHelper.swipes.get3MonthsGrowth);
                break;
            default:
                break;
        }
    }, [selected]);
    
    return (
        <div className="chart-container mt-3 mb-5">
            <div className="dropdown p-0">
                <select value={selected} onChange={handleChange}>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 months</option>
                </select>
            </div>
        {!loading && <Bubble
            data={{
                datasets: [
                    {
                        label: '% Growth Likes',
                        data: [{
                            x: growth.likes,
                            y: count.likes,
                            r: 15
                        }],
                        backgroundColor: "#030102",
                    },
                    {
                        label: '% Growth Dislikes',
                        data: [{
                            x: growth.dislikes,
                            y: count.dislikes,
                            r: 15
                        }],
                        backgroundColor: "#FEC300",
                    },
                    {
                        label: '% Growth Samphie',
                        data: [{
                            x: growth.samphies,
                            y: count.samphies,
                            r: 15
                        }],
                        backgroundColor: "#FE0200",
                    }
                ]
            }}
            className="chart shadow-lg"
            width={500}
            height={500}
            options={{
                maintainAspectRatio: false, 
                responsive: false,
                scales: {
                    x: {
                        ticks: {
                            callback: function(value) {
                                return value + "%";
                            }
                        },
                        beginAtZero: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var label = context.dataset.label || '';
                                if (context.parsed.x !== null) {
                                    label = ["Percentage: " + context.parsed.x + "%", "Count: " + context.parsed.y];
                                }
                                return label;
                            }
                        }
                    }
                }
            }}
        />}
        </div>);
};

export default SwipesGrowthChart;