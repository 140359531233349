import React from "react";
import { withRouter } from "react-router";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { addPostValidationSchema } from "../../validationSchemas/adPost";

const initialValues = {
  adOwnerSerialId: "",
  nameOfCompany: "",
  url: "",
  startDate: "",
  endDate: "",
  totalNumberOfDays: "",
  swipesPerDay: "",
  totalSwipeUps: "",
  firstPaymentStatus: "",
  firstPaymentDate: "",
  secondPaymentStatus: "",
  secondPaymentDate: "",
  businessName: "",
};

let MydOwnerSerialId = "";

class AdForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adOwners: [],
      adOwnerSerialId: "",
      nameOfCompany: "",
      url: "",
      startDate: "",
      endDate: "",
      totalNumberOfDays: 0,
      swipesPerDay: 0,
      totalSwipeUps: 0,
      firstPaymentStatus: "",
      firstPaymentDate: "",
      secondPaymentStatus: "",
      secondPaymentDate: "",
      businessName: "",
    };
  }

  render() {
    return (
      <div className="container p-4">
        <h4>Post Ad Here</h4>
        <hr className="mb-4" />
        <Formik
          initialValues={initialValues}
          validationSchema={addPostValidationSchema}
          onSubmit={this.onSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isValid,
          }) => (
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="row">
                <div className="form-group col-12">
                  <Autocomplete
                    disablePortal
                    options={this.state.adOwners}
                    name="adOwnerSerialId"
                    onChange={this.onTagChange}
                    getOptionLabel={(option) => {
                      MydOwnerSerialId = option.serial_id;
                      return option.name;
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Admin"
                        className="form-control"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div class="form-group col-3">
                  <label>Name Of Company</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameOfCompany"
                    placeholder="Enter Name of Company"
                    defaultChecked={values.nameOfCompany}
                    onChange={handleChange}
                  />
                  {errors.nameOfCompany && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>Url</label>
                  <input
                    type="text"
                    class="form-control"
                    name="url"
                    placeholder="Enter Url"
                    defaultChecked={values.url}
                    onChange={handleChange}
                  />
                  {errors.url && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>Start Date</label>
                  <input
                    type="date"
                    class="form-control"
                    name="startDate"
                    defaultChecked={values.startDate}
                    onChange={handleChange}
                    onInput={(e) => {
                      if(values.totalNumberOfDays === "" || values.totalNumberOfDays === 0){
                        values.endDate = "Na"
                      }
                      else {
                        let future = new Date(e.target.value);
                        future.setDate(future.getDate() + +values.totalNumberOfDays);
                        let date = (future.getMonth()+1)+"/"+future.getDate()+"/"+future.getFullYear()
                        values.endDate = date
                      }
                    }}
                  />
                  {errors.startDate && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>End Date</label>
                  <input
                    type="text"
                    class="form-control"
                    name="endDate"
                    defaultChecked={values.endDate}
                    value={values.endDate}
                    onChange={handleChange}
                    disabled
                  />
                  {errors.endDate && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div class="form-group col-4">
                  <label>Num Of Days</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Num of Days"
                    name="totalNumberOfDays"
                    defaultChecked={values.totalNumberOfDays}
                    onChange={handleChange}
                    onInput={(e) => {
                      if (
                        values.swipesPerDay === "" ||
                        values.swipesPerDay === 0 ||
                        values.swipesPerDay === null ||
                        values.swipesPerDay === undefined
                      ){
                        values.totalSwipeUps = 0;
                      }
                      else {
                        values.totalSwipeUps =
                          e.target.value * values.swipesPerDay;
                      }

                      if(values.startDate === ""){
                        values.endDate = "Na"
                      }
                      else {
                        let future = new Date(values.startDate);
                        future.setDate(future.getDate() + +e.target.value);
                        let date = (future.getMonth()+1)+"/"+future.getDate()+"/"+future.getFullYear()
                        values.endDate = date
                      }
                    }}
                  />
                  {errors.totalNumberOfDays && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-4">
                  <label>Swipes Per Day</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Swipes Per Day"
                    name="swipesPerDay"
                    defaultChecked={values.swipesPerDay}
                    onChange={handleChange}
                    onInput={(e) => {
                      values.totalSwipeUps =
                        e.target.value * values.totalNumberOfDays;
                    }}
                  />
                  {errors.swipesPerDay && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-4">
                  <label>Total Swipes Ups</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Total Swipe Ups"
                    name="totalSwipeUps"
                    defaultChecked={values.totalSwipeUps}
                    onChange={handleChange}
                    value={values.totalSwipeUps}
                    disabled
                  />
                  {errors.totalSwipeUps && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div class="form-group col-3">
                  <label>1st Payment Status</label>
                  <select
                    className="form-control"
                    name="firstPaymentStatus"
                    defaultChecked={values.firstPaymentStatus}
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                  {errors.firstPaymentStatus && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>1st Payment Date</label>
                  <input
                    type="date"
                    class="form-control"
                    name="firstPaymentDate"
                    defaultChecked={values.firstPaymentDate}
                    onChange={handleChange}
                  />
                  {errors.firstPaymentDate && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>2nd Payment Status</label>
                  <select
                    className="form-control"
                    name="secondPaymentStatus"
                    defaultChecked={values.secondPaymentStatus}
                    onChange={handleChange}
                  >
                    <option selected disabled>
                      Select an Option
                    </option>
                    <option>Yes</option>
                    <option>No</option>
                  </select>
                  {errors.secondPaymentStatus && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-3">
                  <label>2nd Payment Date</label>
                  <input
                    type="date"
                    class="form-control"
                    name="secondPaymentDate"
                    defaultChecked={values.secondPaymentDate}
                    onChange={handleChange}
                  />
                  {errors.secondPaymentDate && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div class="form-group col-6">
                  <label>Business Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="businessName"
                    defaultChecked={values.businessName}
                    onChange={handleChange}
                  />
                  {errors.businessName && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
                <div class="form-group col-6">
                  <label>Select Image</label>
                  {/* <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    name="file"
                    formenctype="multipart/form-data"
                    onChange={this.addFileHandler.bind(this)}
                  /> */}
                  <input
                    type="file"
                    accept="image/*"
                    value={undefined}
                    onChange={(event) => {
                      setFieldValue("file", event.currentTarget.files[0]);
                    }}
                    className="form-control"
                  />
                  {errors.file && (
                    <span style={{ color: "red" }}>Required</span>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary col-12"
                    disabled={!isValid}
                  />
                </div>
              </div>
              {/* <button class="btn btn-primary col-12" type="submit">
                Submit
              </button> */}
            </form>
          )}
        </Formik>
      </div>
    );
  }

  onTagChange(event, values) {
    MydOwnerSerialId = values.serial_id;
  }

  onSubmit = async (values) => {
    if (MydOwnerSerialId === "") alert("Select Admin Plz");
    else {
      let headers = {
        Authorization:
          ApiHelper.getAccessTokenHeaderConfig().headers.Authorization,
        Accept: "*/*",
      };

      let formData = new FormData();
      formData.append("file", values.file);
      formData.append("adOwnerSerialId", MydOwnerSerialId);
      formData.append("nameOfCompany", values.nameOfCompany);
      formData.append("url", values.url);
      formData.append("startDate", values.startDate);
      formData.append("endDate", values.endDate);
      formData.append("totalNumberOfDays", values.totalNumberOfDays);
      formData.append("swipesPerDay", values.swipesPerDay);
      formData.append("totalSwipeUps", values.totalSwipeUps);
      formData.append("firstPaymentStatus", values.firstPaymentStatus);
      formData.append("firstPaymentDate", values.firstPaymentDate);
      formData.append("secondPaymentStatus", values.secondPaymentStatus);
      formData.append("secondPaymentDate", values.secondPaymentDate);
      formData.append("businessName", values.businessName);

      fetch(ApiHelper.ads.postNewAd, {
        method: "POST",
        headers: headers,
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.error_message === "Error") alert(res.data);
          else {
            alert(res.error_message);
            this.props.history.push("/adminpanel/all-ads");
          }
        });
    }
  };

  async getAdOwners() {
    try {
      var newstate = { ...this.state };
      var getAdOwners = await axios.get(
        ApiHelper.ads.getAdOwners,
        {},
        ApiHelper.getAccessTokenHeaderConfig()
      );
      newstate.error = false;
      newstate.adOwners = getAdOwners.data.data;
      this.setState(newstate);
    } catch (error) {
      newstate.error = true;
      newstate.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }

  async componentDidMount() {
    this.getAdOwners();
  }
}
export default withRouter(AdForm);
