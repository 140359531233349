//jshint esversion: 6
import React from "react";
import "./rows.css";

const UserReport = ({name, user_id, report_id, reportedBy, selectProfileId, selectReport, header, suspendReportedUser, dismissUserReport}) => {
    return <div className="c_row_container row g-0">
        <div className="col-3">{user_id}</div>
        <div className="col-2">{name}</div>
        <div className="col-2">{reportedBy}</div>
        {!header && 
        <React.Fragment>
            <div className="col-3"><button className="c_row_text_blue_button" onClick={() => {
                selectProfileId(user_id);
                selectReport(report_id);
            }}>View Profile</button></div>
            <div className="col-1"><button className="c_row_accept_button" onClick={() => suspendReportedUser(user_id, name)}>Suspend</button></div>
            <div className="col-1"><button className="c_row_reject_button" onClick={() => dismissUserReport(report_id, name)}>Dismiss</button></div>
        </React.Fragment>}  
    </div>
}

export default UserReport;