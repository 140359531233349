import React from "react"
import { withRouter } from "react-router"
import ChangePassword from "../microforms/ChangePassword"

class Security extends React.Component {
    render(){
        return <div>
            <ChangePassword/>
        </div>
    }
}
export default withRouter(Security)