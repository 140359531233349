import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import { Autocomplete, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { Formik } from "formik";
import { editPostValidationSchema } from "../../validationSchemas/adPost";
import moment from "moment";
moment.locale('en');

let initialValues = {
  adOwnerSerialId: "",
  nameOfCompany: "",
  url: "",
  startDate: "",
  endDate: "",
  totalNumberOfDays: "",
  swipesPerDay: "",
  totalSwipeUps: "",
  firstPaymentStatus: "",
  firstPaymentDate: "",
  secondPaymentStatus: "",
  secondPaymentDate: "",
  businessName: "",
};

const UpdateForm = () => {
  const [ad_id, setAd_id] = useState("");
  const [adOwners, setAdOwners] = useState([]);
  const [ad, setAd] = useState({});
  const [MydOwnerSerialId, setMydOwnerSerialId] = useState("");
  const history = useHistory();

  const onTagChange = (event, values) => {
    setAd({ ...ad, adOwnerSerialId: values.serial_id });
  };

  const onSubmit = async (values) => {
    let headers = {
      Authorization:
        ApiHelper.getAccessTokenHeaderConfig().headers.Authorization,
      Accept: "*/*",
    };

    let formData = new FormData();
    formData.append("id", ad_id);
    if (values.file != null && values.file != undefined)
      formData.append("file", values.file);
    if (values.adOwnerSerialId != null && values.adOwnerSerialId != undefined)
      formData.append("adOwnerSerialId", ad.adOwnerSerialId);
    if (values.nameOfCompany != null && values.nameOfCompany != undefined)
      formData.append("nameOfCompany", values.nameOfCompany);
    if (values.url != null && values.url != undefined)
      formData.append("url", values.url);
    if (values.startDate != null && values.startDate != undefined)
      formData.append("startDate", values.startDate);
    if (values.endDate != null && values.endDate != undefined)
      formData.append("endDate", values.endDate);
    if (
      values.totalNumberOfDays != null &&
      values.totalNumberOfDays != undefined
    )
      formData.append("totalNumberOfDays", values.totalNumberOfDays);
    if (values.swipesPerDay != null && values.swipesPerDay != undefined)
      formData.append("swipesPerDay", values.swipesPerDay);
    if (values.totalSwipeUps != null && values.totalSwipeUps != undefined)
      formData.append("totalSwipeUps", values.totalSwipeUps);
    if (
      values.firstPaymentStatus != null &&
      values.firstPaymentStatus != undefined
    )
      formData.append("firstPaymentStatus", values.firstPaymentStatus);
    if (values.firstPaymentDate != null && values.firstPaymentDate != undefined)
      formData.append("firstPaymentDate", values.firstPaymentDate);
    if (
      values.secondPaymentStatus != null &&
      values.secondPaymentStatus != undefined
    )
      formData.append("secondPaymentStatus", values.secondPaymentStatus);
    if (
      values.secondPaymentDate != null &&
      values.secondPaymentDate != undefined
    )
      formData.append("secondPaymentDate", values.secondPaymentDate);
    if (values.businessName != null && values.businessName != undefined)
      formData.append("businessName", values.businessName);

    formData.append("photographKey", ad.photographKey);

    fetch(ApiHelper.ads.postNewAd + `/${ad.ad_id}`, {
      method: "PATCH",
      headers: headers,
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error_message === "Error") {
          alert(res.data);
        } else {
          alert(res.error_message);
          // history.push("/adminpanel/all-ads");
          history.goBack();
        }
      });
  };

  const getAdOwners = async () => {
    setAdOwners([]);
    var getAdOwners = await axios.get(
      ApiHelper.ads.getAdOwners,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    setAdOwners(getAdOwners.data.data);
  };

  const getAdById = async (id) => {
    var myAd = await axios.get(
      ApiHelper.baseURL + `ad/getad/${id}`,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );
    setAd(myAd.data.data);
    setMydOwnerSerialId(ad.adOwnerSerialId);
  };

  useEffect(() => {
    setAd_id(history.location.state?.ad_id);
    getAdOwners();
    getAdById(history.location.state?.ad_id);
  }, []);

  useEffect(() => {
    initialValues.adOwnerSerialId = ad.adOwnerSerialId;
    initialValues.nameOfCompany = ad.nameOfCompany;
    initialValues.url = ad.url;
    initialValues.startDate = moment(ad.startDate).format("YYYY-MM-DD");
    initialValues.endDate = moment(ad.endDate).format("MM-DD-YYYY");
    initialValues.totalNumberOfDays = ad.totalNumberOfDays;
    initialValues.swipesPerDay = ad.swipesPerDay;
    initialValues.totalSwipeUps = ad.totalSwipeUps;
    initialValues.firstPaymentStatus = ad.firstPaymentStatus;
    initialValues.firstPaymentDate = moment(ad.firstPaymentDate).format("YYYY-MM-DD");
    initialValues.secondPaymentStatus = ad.secondPaymentStatus;
    initialValues.secondPaymentDate = moment(ad.secondPaymentDate).format("YYYY-MM-DD");
    initialValues.businessName = ad.businessName;
  }, [ad]);

  return (
    <div className="container p-4">
      <h4>Update Ad</h4>
      <hr className="mb-4" />
      <Formik
        initialValues={initialValues}
        validationSchema={editPostValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row">
              <div className="form-group col-12">
                <Autocomplete
                  disablePortal
                  options={adOwners}
                  name="adOwnerSerialId"
                  onChange={onTagChange}
                  getOptionLabel={(option) => {
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Admin"
                      className="form-control"
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div class="form-group col-3">
                <label>Name Of Company</label>
                <input
                  type="text"
                  class="form-control"
                  name="nameOfCompany"
                  placeholder="Enter Name of Company"
                  defaultValue={values.nameOfCompany}
                  onChange={handleChange}
                />
                {errors.nameOfCompany && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>Url</label>
                <input
                  type="text"
                  class="form-control"
                  name="url"
                  placeholder="Enter Url"
                  defaultValue={values.url}
                  onChange={handleChange}
                />
                {errors.url && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>Start Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="startDate"
                  defaultValue={values.startDate}
                  onChange={handleChange}
                  onInput={(e) => {
                    if(values.totalNumberOfDays === "" || values.totalNumberOfDays === 0){
                      values.endDate = "Na"
                    }
                    else {
                      let future = new Date(e.target.value);
                      future.setDate(future.getDate() + +values.totalNumberOfDays);
                      let date = (future.getMonth()+1)+"/"+future.getDate()+"/"+future.getFullYear()
                      values.endDate = date
                    }
                  }}
                />
                {errors.startDate && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>End Date</label>
                <input
                  type="text"
                  class="form-control"
                  name="endDate"
                  defaultValue={values.endDate}
                  onChange={handleChange}
                  disabled
                />
                {errors.endDate && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div class="form-group col-4">
                <label>Num Of Days</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Num of Days"
                  name="totalNumberOfDays"
                  defaultValue={values.totalNumberOfDays}
                  onChange={handleChange}
                  onInput={(e) => {
                    if (
                      values.swipesPerDay === "" ||
                      values.swipesPerDay === 0 ||
                      values.swipesPerDay === null ||
                      values.swipesPerDay === undefined
                    ) {
                      values.totalSwipeUps = 0;
                      ad.totalSwipeUps = values.totalSwipeUps;
                      ad.totalNumberOfDays = e.target.value;
                    } else {
                      values.totalSwipeUps =
                        e.target.value * values.swipesPerDay;
                      ad.totalSwipeUps = values.totalSwipeUps;
                      ad.totalNumberOfDays = e.target.value;
                    }

                    if(values.startDate === ""){
                      values.endDate = "Na"
                    }
                    else {
                      let future = new Date(values.startDate);
                      future.setDate(future.getDate() + +e.target.value);
                      let date = (future.getMonth()+1)+"/"+future.getDate()+"/"+future.getFullYear()
                      values.endDate = date
                    }
                  }}
                />
                {errors.totalNumberOfDays && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-4">
                <label>Swipes Per Day</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Swipes Per Day"
                  name="swipesPerDay"
                  defaultValue={values.swipesPerDay}
                  onChange={handleChange}
                  onInput={(e) => {
                    values.totalSwipeUps =
                      e.target.value * values.totalNumberOfDays;
                    ad.totalSwipeUps = values.totalSwipeUps;
                    ad.swipesPerDay = e.target.value;
                  }}
                />
                {errors.swipesPerDay && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-4">
                <label>Total Swipes Ups</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder="Enter Total Swipe Ups"
                  name="totalSwipeUps"
                  defaultValue={values.totalSwipeUps}
                  onChange={handleChange}
                  disabled
                />
                {errors.totalSwipeUps && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div class="form-group col-3">
                <label>1st Payment Status</label>
                <select
                  className="form-control"
                  name="firstPaymentStatus"
                  defaultValue={values.firstPaymentStatus}
                  onChange={handleChange}
                >
                  <option selected disabled>
                    Select an Option
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                {errors.firstPaymentStatus && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>1st Payment Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="firstPaymentDate"
                  defaultValue={values.firstPaymentDate}
                  onChange={handleChange}
                />
                {errors.firstPaymentDate && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>2nd Payment Status</label>
                <select
                  className="form-control"
                  name="secondPaymentStatus"
                  defaultValue={values.secondPaymentStatus}
                  onChange={handleChange}
                >
                  <option selected disabled>
                    Select an Option
                  </option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                {errors.secondPaymentStatus && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-3">
                <label>2nd Payment Date</label>
                <input
                  type="date"
                  class="form-control"
                  name="secondPaymentDate"
                  defaultValue={values.secondPaymentDate}
                  onChange={handleChange}
                />
                {errors.secondPaymentDate && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
            </div>
            <div className="row">
              <div class="form-group col-6">
                <label>Business Name</label>
                <input
                  type="text"
                  class="form-control"
                  name="businessName"
                  defaultValue={values.businessName}
                  onChange={handleChange}
                />
                {errors.businessName && (
                  <span style={{ color: "red" }}>Required</span>
                )}
              </div>
              <div class="form-group col-6">
                <label>Select Image</label>
                {/* <input
                  type="file"
                  accept="image/*"
                  class="form-control"
                  name="file"
                  formenctype="multipart/form-data"
                  onChange={this.addFileHandler.bind(this)}
                /> */}
                <input
                  type="file"
                  accept="image/*"
                  value={undefined}
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }}
                  className="form-control"
                />
                {errors.file && <span style={{ color: "red" }}>Required</span>}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-primary col-12"
                  disabled={!isValid}
                />
              </div>
            </div>
            {/* <button class="btn btn-primary col-12" type="submit">
              Submit
            </button> */}
          </form>
        )}
      </Formik>
    </div>
  );
};

class AdUpdateForm extends React.Component {
  render() {
    return <UpdateForm />;
  }
}
export default withRouter(AdUpdateForm);
