import React from "react"
import styled from "styled-components"
import logo from "./assets/logo.png"
var SplashScreen = () => {
    
    var Div = styled.div`
        position: fixed;
        text-align: center;
        width: 100%
        margin: 0px auto;
        height: 100vh;
        display: flex;
        vertical-align: middle;
        align-items: center;
    `;
    var Img = styled.img`
        position: relative;
        text-align: center;
        width: 120px;
        display: block;
        margin: 0px auto;
    `
    return <Div>
        <Img src={logo}/>
    </Div>
}

export default SplashScreen