import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";

class AddBlogs extends React.Component {
  render() {
    const {
      categories = [],
      catName,
      imageurl,
      onFileChange,
      onAddCategoryClicked,
      onChangeCategoryName,
      titleName,
      onChangeHandlerTitle,
      blogName,
      onChangeHandlerBlog,
    } = this.props;

    return (
      <div className="c_microforms_container">
        <div className="c_microforms_header row g-0">
          <div className="col-10 c_microforms_headertext">Add Blogs</div>
          <div className="col-2 c_microforms_headerbutton"></div>
        </div>
        <div className="c_microforms_main">
          <div className="dropdown p-0">
            <p>Choose category</p>
            <select
              name="categoryDropdown"
              value={catName}
              onChange={onChangeCategoryName}
            >
              {categories.map((item, index) => {
                return (
                  <option value={item.category_name}>
                    {item.category_name}
                  </option>
                );
              })}
            </select>
          </div>
          Select Blog Image
          <br />
          <input
            name="image"
            type="file"
            onChange={onFileChange}
            className="c_microforms_inputfull"
            placeholder="Select Image"
          />
          {imageurl}
        </div>
        <div className="c_microforms_main">
          <input
            title="title"
            value={titleName}
            onChange={onChangeHandlerTitle}
            className="c_microforms_inputfull"
            placeholder="Add Title"
          />
        </div>
        <div className="c_microforms_main">
          <input
            blog="blog"
            value={blogName}
            onChange={onChangeHandlerBlog}
            className="c_microforms_inputfull"
            placeholder="Add Blog Link"
          />
        </div>
        <div className="c_microforms_footer">
          <button
            className="c_microforms_button"
            onClick={onAddCategoryClicked}
          >
            Add Blog
          </button>
        </div>
      </div>
    );
  }
}
export default AddBlogs;
