import React from "react"
import "./rows.css"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"

class LifeStyleCategory extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: this.props.name,
            image: this.props.image,
            serial_id: this.props.serial_id,
            showupdateform:false,
            newname: "",
            newimage: null,
        }
        this.onDeleteClicked = this.props.onDeleteClicked
    }
    render(){
        return <div className="c_row_container row g-0">
            <div className="col-4">{this.state.name}</div>
            <div className="col-4"><a href={this.state.image} target="_blank" rel="noreferrer">Click To View Image</a></div>
            {this.state.header?"":<div className="col-2"><button onClick={this.onDeleteClicked.bind(null, this.state.serial_id, this.state.name)} className="c_row_text_danger_button">Delete</button></div>}
            {this.state.header?"":<div className="col-2"><button onClick={this.onUpdateClicked.bind(this)} className="c_row_text_blue_button">{this.state.showupdateform?"Cancel":"Update"}</button></div>}
            {(() => {
                if(this.state.showupdateform)return <React.Fragment>
                    <input name="newname" onChange={this.updateCategoryFormHandler.bind(this)} className="c_row_inputfull" placeholder="Full Name"/>
                    Select Image<br/>
                    <input name="newimage" type="file" onChange={this.onFileChange.bind(this)} className="c_row_inputfull" placeholder="Select Image"/>
                    {this.state.imageurl}
                    <button className="c_row_button" onClick={this.onUpdateCategoryClicked.bind(this)}>Update Category</button>
                </React.Fragment>
            })()}
        </div>    
    }
    updateCategoryFormHandler(event){
        var newstate = {...this.state}
        newstate[event.target.name] = event.target.value
        this.setState(newstate)
    }
    onFileChange(event){
        var newstate = {...this.state}
        newstate.newimage = event.target.files[0]
        this.setState(newstate)
    }
    onUpdateClicked(){
        const newstate = {...this.state}
        newstate.showupdateform = !newstate.showupdateform
        this.setState(newstate)
    }
    async onUpdateCategoryClicked(){
        try{
            console.log(this.state)
            var newstate = {...this.state}
            var name = this.state.name
            var image = this.state.image
            if(this.state.newimage != null){
                const formdata = new FormData()
                formdata.append("images", this.state.newimage)
                var uploadimage = await axios.post(ApiHelper.appApi.uploadImage, formdata, {'content-type': 'multipart/form-data'})
                if(uploadimage.data.status === 400){
                    alert(uploadimage.data.message)
                    return
                }
                image = uploadimage.data.data.image
            }
            if(this.state.newname !== ""){
                name = this.state.newname
            }
            await axios.post(ApiHelper.categoryScreen.updateLifeStyleCategory, {
                name: name,
                image: image,
                categoryid: this.state.serial_id
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.name = name
            newstate.image = image
            newstate.showupdateform = false
            this.setState(newstate)
        }catch(error){
            console.log(error)
            alert(error.response.data.error_message)
        }    
    }
}
export default LifeStyleCategory