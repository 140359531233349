import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
class AccountDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            serialid:"",
            authority:"",
            name:"",
            emailid:"",
        }
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Account Details</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                Serial ID<br/>
                <input name="serialid" value={this.state.serialid} className="c_microforms_inputfull" placeholder="Serial ID" disabled/>
                Name<br/>
                <input name="name" value={this.state.name} className="c_microforms_inputfull" placeholder="Name" disabled/>
                Email ID<br/>
                <input name="emailid" value={this.state.emailid} className="c_microforms_inputfull" placeholder="Email ID" disabled/>
                Authority<br/>
                <input name="authority" value={this.state.authority} className="c_microforms_inputfull" placeholder="Authority" disabled/>
                <p>{this.state.message}</p><br/>
            </div>
            <div className="c_microforms_footer">
                {/* <button onClick={this.onUpdateClicked.bind(this)} className="c_microforms_button">Update Account</button> */}
            </div>
        </div>
    }
    async componentDidMount(){
        this.getMyAdminCredential()
    }
    async onUpdateClicked(){
        try{
            var newstate = {...this.state}
            var updateadmin = await axios.post(ApiHelper.adminCredentials.getMyAdminCredentialWithSerialID, {}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.message = updateadmin.data.error_message
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
    async getMyAdminCredential(){
        try{
            var newstate = {...this.state}
            var getauthorities = await axios.post(ApiHelper.adminCredentials.getMyAdminCredentialWithSerialID, {}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.serialid = getauthorities.data.data.serial_id
            newstate.name = getauthorities.data.data.name
            newstate.emailid = getauthorities.data.data.emailid
            newstate.authority = getauthorities.data.data.authority
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
}
export default AccountDetails