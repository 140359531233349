import React from "react"
import { withRouter } from "react-router"
import AddQuotes from "../microforms/AddQuotes"
import QuotesList from "../microforms/QuotesList"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"

class Account extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error: false,
            message: "",
            category_name: "",
            categories: [],
            quotes: [],
            catName: "",
            image: "",
            
        }
    }
    
    componentDidMount() {
        this.getCategories()
        this.getQuotes()
    }

    getCategories = async () => {
        try {
            const categoriesResponse = await axios.post(ApiHelper.readablecontent.getAllCategories, { offset: 0 }, ApiHelper.getAccessTokenHeaderConfig())
            this.setState({ categories: categoriesResponse.data.data, catName: categoriesResponse.data.data.length ? categoriesResponse.data.data[0].category_name : '' })
        } catch (error) {
        }
    }

    getQuotes = async () => {
        try {
            const quotesResponse = await axios.post(ApiHelper.readablecontent.getAllQuotes, { offset: 0 }, ApiHelper.getAccessTokenHeaderConfig())
            this.setState({ quotes: quotesResponse.data.data })
        } catch (error) {
        }
    }

    onFileChange = (event) => {
        var newstate = {...this.state}
        newstate.image = event.target.files[0]
        this.setState(newstate)
    }

    onDeleteQuote = async (quote_id) => {
        try {
            await axios.post(ApiHelper.readablecontent.deleteQuotes, {
                quote_id: quote_id
            }, ApiHelper.getAccessTokenHeaderConfig())
            this.getQuotes()
        } catch (error) {
        }
    }

 onAddCategoryClicked = async () => {
    // console.log(this.state)
    try{
        var newstate = {...this.state}
        const formdata = new FormData()
        formdata.append("images", this.state.image)
        var uploadimage = await axios.post(ApiHelper.appApi.uploadImage, formdata, {'content-type': 'multipart/form-data'})
        newstate.message = uploadimage.data.message
        if(uploadimage.data.status === 400){
            this.setState(newstate)
            return
        }
        var imageurl = uploadimage.data.data.image
        var createQuotes = await axios.post(ApiHelper.readablecontent.createQuotes, {
            category: this.state.catName,
            quote: imageurl
        }, ApiHelper.getAccessTokenHeaderConfig())
        newstate.message = createQuotes.data.error_message
        this.setState(newstate)
        this.getQuotes()
    }catch(error){
        console.log(error)
        newstate.error = true
        newstate.message = error.response.data.error_message
        this.setState(newstate)
    }    
}

onChangeCategoryName = (event) => {
    this.setState({
        catName: event.target.value
    })
}

    render(){
        return <div>
        <AddQuotes
        categories={this.state.categories}
        catName={this.state.catName}
        imageurl={this.state.imageurl}
        image={this.state.image}
        onFileChange={this.onFileChange}
        onChangeCategoryName={this.onChangeCategoryName}
        onAddCategoryClicked={this.onAddCategoryClicked}
        />
        <QuotesList
        quotes={this.state.quotes}
        onDeleteQuote={this.onDeleteQuote}
        />
    </div>
    }
}
export default withRouter(Account)