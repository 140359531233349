import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";

class PoetryList extends React.Component {
  render() {
    const { quotes = [], onDeletePoetry } = this.props;
    return (
      <div className="c_microforms_container">
        <div className="c_microforms_header row g-0">
          <div className="col-10 c_microforms_headertext">
            Visible Poetry to users right now. (If not swipped)
          </div>
          <div className="col-2 c_microforms_headerbutton"></div>
        </div>
        <div className="c_microforms_main">
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <th>S.No</th>
                <th>Poetry</th>
                <th>Category</th>
                <th>Likes</th>
                <th>Shares</th>
                <th>Action</th>
              </tr>
              {quotes.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <a href={item.joke} target="_blank" rel="noreferrer">
                        <div>View Poetry</div>
                      </a>
                    </td>
                    <td>{item.category || "None"}</td>
                    <td>{item.likes || 0}</td>
                    <td>{item.shares || 0}</td>
                    <td>
                      <span
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => onDeletePoetry(item.joke_id)}
                      >
                        Delete
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default PoetryList;
