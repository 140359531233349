import React from "react"
import { withRouter } from "react-router"
import TotalFemaleUsers from "../countblocks/TotalFemaleUsers"
import TotalMaleUsers from "../countblocks/TotalMaleUsers"
import TotalPaidUsers from "../countblocks/TotalPaidUsers"
import TotalSamphionisters from "../countblocks/TotalSamphionisters"
import TotalMaleSamphionisters from "../countblocks/TotalMaleSamphionisters"
import TotalFemaleSamphionisters from "../countblocks/TotalFemaleSamphionisters"
import GrowthChart from "../charts/GrowthChart"
import SwipesGrowthChart from "../charts/SwipesGrowthChart"
import UsersChart from "../charts/VerifiedUsersChart"
import VerifiedUsersChart from "../charts/UsersChart"
class Statistics extends React.Component {
    render(){
        return <React.Fragment>
            <div>
                <TotalFemaleUsers/>
                <TotalMaleUsers/>
                <TotalPaidUsers/>
                <TotalSamphionisters/>
                <TotalMaleSamphionisters/>
                <TotalFemaleSamphionisters/>
            </div>
            <div className="row ms-auto">
                <GrowthChart />
                <SwipesGrowthChart />
            </div>
            <div className="row ms-auto">
                <VerifiedUsersChart />
                <UsersChart />
            </div>
        </React.Fragment>
    }
}
export default withRouter(Statistics)