//jshint esversion: 6
import React from "react";
import "./rows.css";

const VerificationRequest = ({requestId, name, user_id, selectProfileId, selectVerificationRequest, header, acceptVerificationRequest, rejectVerificationRequest}) => {
    return <div className="c_row_container row g-0">
        <div className="col-2">{user_id}</div>
        <div className="col-4">{name}</div>
        {!header && 
        <React.Fragment>
            <div className="col-3"><button className="c_row_text_blue_button" onClick={() => {
                selectProfileId(user_id)
                selectVerificationRequest(requestId);
            }}>View Profile</button></div>
            <div className="col-1"><button className="c_row_accept_button" onClick={() => acceptVerificationRequest(user_id, name)}>Accept</button></div>
            <div className="col-1"><button className="c_row_reject_button" onClick={() => rejectVerificationRequest(user_id, name)}>Reject</button></div>
        </React.Fragment>}  
    </div>
}

export default VerificationRequest;