//jshint esversion: 6
import React from "react";
import "./rows.css";

const User = ({name, user_id, verified, samphionister, handleVerification, handleSamphionister, selectProfileId, header}) => {
    return <div className="c_row_container row g-0">
        <div className="col-3">{user_id}</div>
        <div className="col-3">{name}</div>
        {!header && 
        <React.Fragment>
            <div className="col-2"><button className="c_row_text_blue_button" onClick={() => selectProfileId(user_id)}>View Profile</button></div>
            <div className="col-1">
                {verified === "yes" ?
                <button className="c_row_text_danger_button" onClick={() => handleVerification({user_id, name, flag: "no"})}>Unverify</button>
                :
                <button className="c_row_text_blue_button" onClick={() => handleVerification({user_id, name, flag: "yes"})}>Verify</button>}
            </div>
            <div className="col-3">
                {samphionister === "yes" ?
                <button onClick={() => handleSamphionister({user_id, name, flag: "no"})} className="c_row_text_danger_button">Remove Samphionister</button>
                :
                <button onClick={() => handleSamphionister({user_id, name, flag: "yes"})} className="c_row_text_danger_button">Give Samphionister</button>}
            </div>
        </React.Fragment>}  
    </div>
}

export default User;