//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import "./charts.css";
import {Bar} from "react-chartjs-2";
import ApiHelper from "src/model/ApiHelper";


const UsersChart = () => {
    const [selected, setSelected] = useState("Daily");
    const [verifiedPercentage, setVerifiedPercentage] = useState({});
    const [verifiedCount, setVerifiedCount] = useState({});
    const [samphionistersPercentage, setSamphionistersPercentage] = useState({});
    const [loading, setLoading] = useState(true);

    function handleChange(event) {
        setSelected(event.target.value);
        setLoading(true);
    }

    useEffect(() => {
        async function getPercentageOfVerifiedProfiles() {
            try{
                const males = await axios.post(ApiHelper.userStatistics.getPercentageOfVerifiedProfilesByPeriod, {gender: "male", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const females = await axios.post(ApiHelper.userStatistics.getPercentageOfVerifiedProfilesByPeriod, {gender: "female", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = {males: males.data.data, females: females.data.data};
                setVerifiedPercentage(data);
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        async function getCountOfVerifiedProfiles() {
            try{
                const males = await axios.post(ApiHelper.userStatistics.getCountOfVerifiedProfilesByPeriod, {gender: "male", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const females = await axios.post(ApiHelper.userStatistics.getCountOfVerifiedProfilesByPeriod, {gender: "female", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = {males: males.data.data, females: females.data.data};
                setVerifiedCount(data);
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        async function getPercentageOfSamphionisters() {
            try{
                const males = await axios.post(ApiHelper.userStatistics.getPercentageOfSamphionistersByPeriod, {gender: "male", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const females = await axios.post(ApiHelper.userStatistics.getPercentageOfSamphionistersByPeriod, {gender: "female", period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = {males: males.data.data, females: females.data.data};
                setSamphionistersPercentage(data);
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        
        getPercentageOfVerifiedProfiles();
        getCountOfVerifiedProfiles();
        getPercentageOfSamphionisters();
    }, [selected]);
    
    return (
        <div className="chart-container mt-3 mb-5">
            <div className="dropdown p-0">
                <select value={selected} onChange={handleChange} name="frequency">
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 months</option>
                </select>
            </div>
        {!loading && <Bar 
            data={{
                labels: ["% Verified Profiles", "Total Verified Profiles", "% Samphionister Profiles"],
                datasets: [
                    {
                        label: selected + " Male Users",
                        backgroundColor: "#FE0000",
                        data: [verifiedPercentage.males, verifiedCount.males, samphionistersPercentage.males]
                    },
                    {
                        label: selected + " Female Users",
                        backgroundColor: "#FFF3CD",
                        data: [verifiedPercentage.females, verifiedCount.females, samphionistersPercentage.females]
                    }
                ]
            }}
            className="chart shadow-lg"
            width={500}
            height={500}
            options={{
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var datasetLabel = context.dataset.label || '';
                                if (datasetLabel) {
                                    datasetLabel += ': ';
                                }
                                if (context.parsed.x !== null && context.label !== "Total Verified Profiles") {
                                    datasetLabel += context.parsed.x + "%";
                                } else if(context.label === "Total Verified Profiles") {
                                    datasetLabel += context.parsed.x;
                                }
                                return datasetLabel;
                            }
                        }
                    }
                },
                indexAxis: 'y',
                maintainAspectRatio: false, 
                responsive: false 
            }}
        />}
        </div>
    );
};

export default UsersChart;