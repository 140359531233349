import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
class UpdateAccount extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            name:"",
            emailid:"",
        }
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Update Account</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                Name<br/>
                <input name="name" value={this.state.name} onChange={this.updateAccountFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Name"/>
                Email ID<br/>
                <input name="emailid" value={this.state.emailid} onChange={this.updateAccountFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Email ID"/>
                <p>{this.state.message}</p>
            </div>
            <div className="c_microforms_footer">
                <button onClick={this.onUpdateClicked.bind(this)} className="c_microforms_button">Update Account</button>
            </div>
        </div>
    }
    updateAccountFormHandler(event){
        var newstate = {...this.state}
        newstate.message = ""
        newstate[event.target.name] = event.target.value
        if(newstate.newpassword !== newstate.confirmpassword)newstate.message = "Passwords Do Not Match"
        this.setState(newstate)
    }
    async componentDidMount(){
        this.getMyAdminCredential()
    }
    async onUpdateClicked(){
        try{
            var newstate = {...this.state}
            var updateadmin = await axios.post(ApiHelper.adminCredentials.getMyAdminCredentialWithSerialID, {}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.message = updateadmin.data.error_message
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
    async getMyAdminCredential(){
        try{
            var newstate = {...this.state}
            var getauthorities = await axios.post(ApiHelper.adminCredentials.getMyAdminCredentialWithSerialID, {}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.name = getauthorities.data.data.name
            newstate.emailid = getauthorities.data.data.emailid
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
}
export default UpdateAccount