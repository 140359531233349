/* eslint-disable no-useless-escape */
//jshint esversion: 9
import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";

const RequestedProfile = ({profileId, requestId, error, showVerifiedUser, acceptVerificationRequest, rejectVerificationRequest}) =>  {
    const [profile, setProfile] = useState({});
    const [request, setRequest] = useState({});
    const requestedProfileDiv = React.useRef(null);

    useEffect(() => {
        async function getUserDetails() {
            try{
                const requestedprofile = await axios.post(ApiHelper.userDetails.getUserDetails, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                const userMatches = await axios.post(ApiHelper.userDetails.getUserMatches, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                if(!showVerifiedUser) {
                    const verificationrequest = await axios.post(ApiHelper.verificationRequests.getVerificationRequest, {serial_id: requestId}, ApiHelper.getAccessTokenHeaderConfig());
                    setRequest(verificationrequest.data.data);
                }
                var data = requestedprofile.data.data;
                var matches = userMatches.data.data;

                if(data) {
                    setProfile(data);
                }
                if(matches || matches === 0) {
                    setProfile({...data, matches});
                }
            }catch(error){
                return 0;
            }
        }
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId]);

    useEffect(() => {
        requestedProfileDiv.current.scrollIntoView();
    }, []);
    
    return <div className="c_microforms_container" ref={requestedProfileDiv}>
        <div className="c_microforms_header row g-0">
            <div className="col-10 c_microforms_headertext">Requested Profile</div>
            {error && <div style={{color: "red"}}>{error}</div>}
            <div className="c_microforms_main row">
                {profile.user_photo ? 
                    <img className="c_microforms_profile_picture col-2 p-0" src={profile.user_photo} alt="Profile" />
                    :
                    <span style={{color: "red"}}>This user doesn't have a profile picture.</span>
                }
                <div className="col-5 mb-3">
                    <div className="c_microforms_username">{!showVerifiedUser ? request.name : profile.user_name}</div>
                    <div className="m-3">
                        <div><b>Total Matches:</b> {profile.matches}</div>
                        <div><b>Paid User:</b> {profile.paid_user}</div>
                        <div className="mt-3">
                            <div><b>Location:</b> {profile.user_location}</div>
                            <div><b>Email:</b> {!showVerifiedUser ? request.emailid : profile.email}</div>
                            <div><b>Contact:</b> {!showVerifiedUser ? request.mobileno : profile.phone}</div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 p-0 w-auto">
                    {profile.life_snaps &&
                        <React.Fragment>
                        <b>Lifesnaps: </b>
                        <br />
                            {profile.life_snaps.map(lifesnap => <img className="c_microforms_uploaded_images mb-3" src={lifesnap} alt="Lifesnap" />)}   
                        </React.Fragment>
                    }
                    <br />
                    {profile.interests &&
                        <React.Fragment>
                        <b>Interest Images: </b>
                        <br />
                            {profile.interests.map(interest => {
                                return (
                                    interest.interest &&
                                    <React.Fragment>
                                        <span>{interest.interest}:</span>
                                        <br />
                                        {interest.int_images.map(image => <img className="c_microforms_uploaded_images" src={image} alt="Interest" />)}
                                        <br />
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    }
                </div>
                {!showVerifiedUser &&
                <div className="ms-5 mt-1 mb-4 w-auto">
                    <button className="btn btn-success btn-lg px-5 me-4" onClick={() => acceptVerificationRequest(profileId, profile.user_name)}>Accept</button>
                    <button className="btn btn-danger btn-lg px-5" onClick={() => rejectVerificationRequest(profileId, profile.user_name)}>Reject</button>
                </div>}
            </div>
        </div>
    </div>
};

export default RequestedProfile;