import React from "react"
import { withRouter } from "react-router"
import VerifiedUsers from "../microforms/VerifiedUsers"

class VerifiedUsersPage extends React.Component {
    render(){
        return <div>
            <VerifiedUsers />
        </div>
    }
}
export default withRouter(VerifiedUsersPage)