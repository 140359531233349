import React from "react"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
class AddLifeStyleCategory extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            authorities: [],
            name: "",
            image: "",
        }
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Add LifeStyle Category</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                <input name="name" onChange={this.addCategoryFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Full Name"/>
                Select Image<br/>
                <input name="image" type="file" onChange={this.onFileChange.bind(this)} className="c_microforms_inputfull" placeholder="Select Image"/>
                {this.state.imageurl}
                <p>{this.state.message}</p>
            </div>
            <div className="c_microforms_footer">
                <button className="c_microforms_button" onClick={this.onAddCategoryClicked.bind(this)}>Add Category</button>
            </div>
        </div>
    }
    onFileChange(event){
        var newstate = {...this.state}
        newstate.message = ""
        newstate.image = event.target.files[0]
        this.setState(newstate)
    }
    addCategoryFormHandler(event){
        var newstate = {...this.state}
        newstate.message = ""
        newstate[event.target.name] = event.target.value
        this.setState(newstate)
    }
    async onAddCategoryClicked(){
        // console.log(this.state)
        try{
            var newstate = {...this.state}
            const formdata = new FormData()
            formdata.append("images", this.state.image)
            var uploadimage = await axios.post(ApiHelper.appApi.uploadImage, formdata, {'content-type': 'multipart/form-data'})
            newstate.message = uploadimage.data.message
            if(uploadimage.data.status === 400){
                this.setState(newstate)
                return
            }
            var imageurl = uploadimage.data.data.image
            var addcategory = await axios.post(ApiHelper.categoryScreen.addLifeStyleCategory, {
                name: this.state.name,
                image: imageurl
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.message = addcategory.data.error_message
            this.setState(newstate)
        }catch(error){
            console.log(error)
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }    
    }
    async componentDidMount(){

    }
}
export default AddLifeStyleCategory