import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            oldpassword:"",
            newpassword:"",
            confirmpassword:"",
        }
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Change Password</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                <input name="oldpassword" onChange={this.changePasswordFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Old Password"/>
                <input name="newpassword" onChange={this.changePasswordFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="New Password"/>
                <input name="confirmpassword" onChange={this.changePasswordFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Confirm New Password"/>
                <p>{this.state.message}</p>
            </div>
            <div className="c_microforms_footer">
                <button onClick={this.onChangePasswordClicked.bind(this)} className="c_microforms_button">Change Password</button>
            </div>
        </div>
    }
    changePasswordFormHandler(event){
        var newstate = {...this.state}
        newstate.message = ""
        newstate[event.target.name] = event.target.value
        if(newstate.newpassword !== newstate.confirmpassword)newstate.message = "Passwords Do Not Match"
        this.setState(newstate)
    }
    async onChangePasswordClicked(){
        if(this.state.newpassword !== this.state.confirmpassword) return
        try{
            var newstate = {...this.state}
            var updatepassword = await axios.post(ApiHelper.adminCredentials.updatePasswordWithSerialID, {
                oldpassword: this.state.oldpassword,
                newpassword: this.state.newpassword
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.message = updatepassword.data.error_message
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
    async componentDidMount(){

    }
}
export default ChangePassword