//jshint esversion: 9
import React, {useState, useEffect} from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
import VerificationRequest from "../rows/VerificationRequest";
import RequestedProfile from "./RequestedProfile";

const VerificationRequests = () => {
    const [error, setError] = useState("");
    const [verificationRequests, setVerificationRequests] = useState([]);
    const [selectedVerificationRequest, setSelectedVerificationRequest] = useState({});
    const [selectedProfileId, setSelectedProfileId] = useState(null);

    async function getVerificationRequests(){
        try{
            const verificationrequests = await axios.post(ApiHelper.verificationRequests.getVerificationRequests, ApiHelper.getAccessTokenHeaderConfig());
            setVerificationRequests(verificationrequests.data.data);
        }catch(error){
            setError(error.response.data.error_message);
        }
    }

    async function acceptVerificationRequest(user_id, user_name){
        try{
            const confirmation = window.confirm("Are you sure you want to accept " + user_name + "'s verification request?");
            if(confirmation) {
                await axios.post(ApiHelper.verificationRequests.acceptVerificationRequest, {user_id}, ApiHelper.getAccessTokenHeaderConfig());
                getVerificationRequests();
                setSelectedProfileId(null);
            }
        } catch(error) {
            setError(error.response.data.error_message);
        }
    }

    async function rejectVerificationRequest(user_id, user_name){
        try{
            const confirmation = window.confirm("Are you sure you want to reject " + user_name + "'s verification request?");
            if(confirmation) {
                await axios.post(ApiHelper.verificationRequests.rejectVerificationRequest, {user_id}, ApiHelper.getAccessTokenHeaderConfig());
                getVerificationRequests();
                setSelectedProfileId(null);
            }    
        } catch(error) {
            setError(error.response.data.error_message);
        }
    }

    function showVerificationRequests(){
        var rows = [];
        for(var i = 0; i < verificationRequests.length; i++){
            var verificationRequest = verificationRequests[i];
            rows.push(<VerificationRequest key={verificationRequest.serial_id} requestId={verificationRequest.serial_id} header={false} user_id={verificationRequest.user_id} name={verificationRequest.name} selectProfileId={setSelectedProfileId} selectVerificationRequest={setSelectedVerificationRequest} acceptVerificationRequest={acceptVerificationRequest} rejectVerificationRequest={rejectVerificationRequest} />);
        }
        return rows;
    }

    useEffect(() => {
        getVerificationRequests();
    }, []);

    return <React.Fragment>
    <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Verification Requests</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            {error && <div className="ms-4" style={{color: "red"}}>{error}</div>}
            <div className="c_microforms_main">
            <VerificationRequest key={-1} header={true} user_id={<b>User ID</b>} name={<b>User Name</b>} />
                {showVerificationRequests()}
            </div>
            <div className="c_microforms_footer row g-0">
                <div className="offset-6">
                    <span onClick={getVerificationRequests} className="c_microforms_horizontalpaddedtextbutton">Refresh</span>
                </div>
            </div>
            {error && <div className="ms-4" style={{color: "red"}}>{error}</div>}
    </div>
    {selectedProfileId && <RequestedProfile key={selectedProfileId} error={error} profileId={selectedProfileId} requestId={selectedVerificationRequest} acceptVerificationRequest={acceptVerificationRequest} rejectVerificationRequest={rejectVerificationRequest} />}
    </React.Fragment>
}

export default VerificationRequests;