import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import moment from "moment";

const AllAdsPage = () => {
  const [allAds, setAllAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function getAllAds() {
    setAllAds([]);
    setIsLoading(true);
    var getAds = await axios.get(
      ApiHelper.ads.getAdByOwnerId +
        `/${ApiHelper.localStorage.getAdminData().serial_id}`,
      {},
      ApiHelper.getAccessTokenHeaderConfig()
    );

    setIsLoading(false);
    setAllAds(getAds.data.data);
  }

  useEffect(() => {
    getAllAds();
  }, []);

  function showSpinner() {
    return (
      <div
        class="d-flex align-items-center justify-content-center"
        style={{ height: "350px" }}
      >
        <div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    );
  }

  function showNoAds() {
    return (
      <div>
        <h4>No Ads Yet</h4>
        <hr className="mb-4" />
      </div>
    );
  }

  function showAllAds() {
    return (
      <div>
        <h4>Your Ads</h4>
        <hr className="mb-4" />
        <table class="table text-center">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Image</th>
              <th scope="col">Company Name</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Num of days</th>
              <th scope="col">Total Swipe ups</th>
              <th scope="col">Swipes Per day</th>
              <th scope="col">Remaining Swipe ups</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {allAds.map((ad) => (
              <tr style={{ backgroundColor: ad.deleted ? "#F0EDED" : "white" }}>
                <th class="align-middle text-center" scope="row">
                  {ad.ad_id}
                </th>
                <td class="align-middle text-center">
                  <img
                    src={ad.photographUrl}
                    alt={ad.ad_id}
                    style={{ width: "150px", height: "100px" }}
                  />
                </td>
                <td class="align-middle text-center">{ad.nameOfCompany}</td>
                <td class="align-middle text-center">
                  {moment(ad.startDate).format("DD/MM/YYYY")}
                </td>
                <td class="align-middle text-center">
                  {moment(ad.endDate).format("DD/MM/YYYY")}
                </td>
                <td class="align-middle text-center">{ad.totalNumberOfDays}</td>
                <td class="align-middle text-center">{ad.totalSwipeUps}</td>
                <td class="align-middle text-center">{ad.swipesPerDay}</td>
                <td class="align-middle text-center">
                  {ad.totalSwipesRemaining}
                </td>
                <td class="align-middle text-center">
                  {ad.deleted ? <>Deleted</> : <>Available</>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  function showAds() {
    return <div>{allAds.length == 0 ? showNoAds() : showAllAds()}</div>;
  }

  return (
    <div className="container p-4">{isLoading ? showSpinner() : showAds()}</div>
  );
};

class MyAds extends React.Component {
  render() {
    return (
      <div>
        <AllAdsPage />
      </div>
    );
  }
}
export default withRouter(MyAds);
