import React from "react"
import "./rows.css"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"

class Interest extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            name: this.props.name,
            image: this.props.image,
            serial_id: this.props.serial_id,
            showupdateform:false,
            newname: "",
        }
        this.onDeleteClicked = this.props.onDeleteClicked
    }
    render(){
        return <div className="c_row_container row g-0">
            <div className="col-4">{this.state.name}</div>
            {this.state.header?"":<div className="col-2"><button onClick={this.onDeleteClicked.bind(null, this.state.serial_id, this.state.name)} className="c_row_text_danger_button">Delete</button></div>}
            {this.state.header?"":<div className="col-2"><button onClick={this.onUpdateClicked.bind(this)} className="c_row_text_blue_button">{this.state.showupdateform?"Cancel":"Update"}</button></div>}
            {(() => {
                if(this.state.showupdateform)return <React.Fragment>
                    <input name="newname" onChange={this.updateCategoryFormHandler.bind(this)} className="c_row_inputfull" placeholder="Full Name"/>
                    <button className="c_row_button" onClick={this.onUpdateCategoryClicked.bind(this)}>Update Interest</button>
                </React.Fragment>
            })()}
        </div>    
    }
    updateCategoryFormHandler(event){
        var newstate = {...this.state}
        newstate[event.target.name] = event.target.value
        this.setState(newstate)
    }
    onFileChange(event){
        var newstate = {...this.state}
        newstate.newimage = event.target.files[0]
        this.setState(newstate)
    }
    onUpdateClicked(){
        const newstate = {...this.state}
        newstate.showupdateform = !newstate.showupdateform
        this.setState(newstate)
    }
    async onUpdateCategoryClicked(){
        try{
            if(this.state.newname === "") return this.onUpdateClicked()
            var newstate = {...this.state}
            await axios.post(ApiHelper.primaryInterest.updateInterest, {
                name: this.state.newname,
                interestid: this.state.serial_id
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.name = this.state.newname
            newstate.showupdateform = false
            this.setState(newstate)
        }catch(error){
            console.log(error)
            alert(error.response.data.error_message)
        }    
    }
}
export default Interest