import React from "react"
import "./rows.css"

var AdminCredential = function(props){
    return <div className="c_row_container row g-0">
        <div className="col-4">{props.emailid}</div>
        <div className="col-3">{props.authority}</div>
        {props.header?"":<div className="col-2"><button onClick={props.onDeleteClicked.bind(null, props.serial_id, props.name)} className="c_row_text_danger_button">Delete</button></div>}
    </div>
}
export default AdminCredential