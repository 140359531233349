//jshint esversion: 6
import React from "react";
import "./rows.css";

const ExclusiveUser = ({name, user_id, selectProfileId, header, excludeUser}) => {
    return <div className="c_row_container row g-0">
        <div className="col-3">{user_id}</div>
        <div className="col-3">{name}</div>
        {!header && 
        <React.Fragment>
            <div className="col-3"><button className="c_row_text_blue_button" onClick={() => selectProfileId(user_id)}>View Profile</button></div>
            <div className="col-2"><button onClick={() => excludeUser(user_id, name)} className="c_row_text_danger_button">Exclude</button></div>
        </React.Fragment>}  
    </div>
}

export default ExclusiveUser;