import * as Yup from "yup";

export const addPostValidationSchema = Yup.object().shape({
  nameOfCompany: Yup.string().required(),
  url: Yup.string().required(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  totalNumberOfDays: Yup.number().required(),
  swipesPerDay: Yup.string().required(),
  totalSwipeUps: Yup.string().required(),
  firstPaymentStatus: Yup.string().required(),
  secondPaymentStatus: Yup.string().required(),
  businessName: Yup.string().required(),
  file: Yup.mixed().required(),
});

export const editPostValidationSchema = Yup.object().shape({
  nameOfCompany: Yup.string(),
  startDate: Yup.string(),
  endDate: Yup.string(),
  totalNumberOfDays: Yup.number(),
  swipesPerDay: Yup.string(),
  totalSwipeUps: Yup.string(),
  firstPaymentStatus: Yup.string(),
  secondPaymentStatus: Yup.string(),
  businessName: Yup.string(),
  file: Yup.mixed(),
});
