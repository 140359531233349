import React from "react"
import { withRouter } from "react-router"
import UserReports from "../microforms/UserReports"

class ReportsPage extends React.Component {
    render(){
        return <div>
            <UserReports />
        </div>
    }
}
export default withRouter(ReportsPage)