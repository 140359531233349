/* eslint-disable no-useless-escape */
//jshint esversion: 9
import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";

const SuspendedProfile = ({profileId}) =>  {
    const [profile, setProfile] = useState({});
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const requestedProfileDiv = React.useRef(null);

    useEffect(() => {
        async function getUserDetails() {
            try{
                const requestedprofile = await axios.post(ApiHelper.userDetails.getSuspendedUserDetails, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                const userMatches = await axios.post(ApiHelper.userDetails.getUserMatches, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                setError(false);

                var data = requestedprofile.data.data;
                var matches = userMatches.data.data;

                if(data) {
                    setProfile(data);
                }
                if(matches || matches === 0) {
                    setProfile({...data, matches});
                }
            }catch(error){
                setError(true);
                setMessage(error.response.data.error_message);
            }
        }
        getUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId]);

    useEffect(() => {
        requestedProfileDiv.current.scrollIntoView();
    }, []);
    
    return <div className="c_microforms_container" ref={requestedProfileDiv}>
        <div className="c_microforms_header row g-0">
            <div className="col-10 c_microforms_headertext">Suspended Profile</div>
            {error && message}
            <div className="c_microforms_main row">
                {profile.user_photo ? 
                    <img className="c_microforms_profile_picture col-2 p-0" src={profile.user_photo} alt="Profile" />
                    :
                    <span style={{color: "red"}}>This user doesn't have a profile picture.</span>
                }
                <div className="col-5">
                    <div className="c_microforms_username">{profile.user_name}</div>
                    <div className="m-3">
                        <div><b>Total Matches:</b> {profile.matches}</div>
                        <div><b>Paid User:</b> {profile.paid_user}</div>
                        <div className="mt-3">
                            <div><b>Location:</b> {profile.user_location}</div>
                            <div><b>Email:</b> {profile.email}</div>
                            <div><b>Contact:</b> {profile.phone}</div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 p-0 w-auto">
                {profile.life_snaps &&
                    <React.Fragment>
                    <b>Lifesnaps: </b>
                    <br />
                        {profile.life_snaps.map(lifesnap => <img className="c_microforms_uploaded_images mb-3" src={lifesnap} alt="Lifesnap" />)}   
                    </React.Fragment>
                }
                <br />
                {profile.interests &&
                    <React.Fragment>
                    <b>Interest Images: </b>
                    <br />
                        {profile.interests.map(interest => {
                            return (
                                interest.interest &&
                                <React.Fragment>
                                    <span>{interest.interest}:</span>
                                    <br />
                                    {interest.int_images.map(image => <img className="c_microforms_uploaded_images" src={image} alt="Interest" />)}
                                    <br />
                                </React.Fragment>
                            );
                        })}
                        </React.Fragment>
                    }
                </div>
            </div>
        </div>
    </div>
};

export default SuspendedProfile;