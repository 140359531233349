//jshint esversion: 9
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./charts.css";
import {Bubble} from "react-chartjs-2";
import ApiHelper from "src/model/ApiHelper";

const GrowthChart = () => {
    const [selected, setSelected] = useState("Daily");
    const [count, setCount] = useState({});
    const [growth, setGrowth] = useState({});
    const [loading, setLoading] = useState(true);

    function handleChange(event) {
        setSelected(event.target.value);
        setLoading(true);
    }

    useEffect(() => {
        async function getDownloadsGrowth() {
            try{
                const response = await axios.post(ApiHelper.userStatistics.getDownloadsGrowth, {period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = response.data.data;
                setGrowth((prevValue) => {
                    return {...prevValue, downloads: data[0]};
                });
                setCount((prevValue) => {
                    return {...prevValue, downloads: data[1]};
                });
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        async function getMembershipGrowth() {
            try{
                const response = await axios.post(ApiHelper.userStatistics.getMembershipGrowth, {period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = response.data.data;
                setGrowth((prevValue) => {
                    return {...prevValue, memberships: data[0]};
                });
                setCount((prevValue) => {
                    return {...prevValue, memberships: data[1]};
                });
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        async function getUpliftGrowth() {
            try{
                const response = await axios.post(ApiHelper.userStatistics.getUpliftGrowth, {period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = response.data.data;
                setGrowth((prevValue) => {
                    return {...prevValue, uplifts: data[0]};
                });
                setCount((prevValue) => {
                    return {...prevValue, uplifts: data[1]};
                });
                setLoading(false);
            }catch(error){
                return error;
            }
        }
        async function getSamphiePackGrowth() {
            try{
                const response = await axios.post(ApiHelper.userStatistics.getSamphiePackGrowth, {period: selected}, ApiHelper.getAccessTokenHeaderConfig());
                const data = response.data.data;
                setGrowth((prevValue) => {
                    return {...prevValue, samphiePacks: data[0]};
                });
                setCount((prevValue) => {
                    return {...prevValue, samphiePacks: data[1]};
                });
                setLoading(false);
            }catch(error){
                return error;
            }
        }

        getDownloadsGrowth();
        getMembershipGrowth();
        getUpliftGrowth();
        getSamphiePackGrowth();
    }, [selected]);
    
    return (
        <div className="chart-container mx-lg-4 mb-5 mt-3">
            <div className="dropdown p-0">
                <select value={selected} onChange={handleChange} name="frequency">
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="3 Months">3 months</option>
                </select>
            </div>
        {!loading && <Bubble
            data={{
                datasets: [
                    {
                        label: '% Growth Downloads',
                        data: [{
                            x: growth.downloads,
                            y: count.downloads,
                            r: 15
                        }],
                        backgroundColor: "#030102",
                    },
                    {
                        label: '% Growth Membership',
                        data: [{
                            x: growth.memberships,
                            y: count.memberships,
                            r: 15
                        }],
                        backgroundColor: "#FEC300",
                    },
                    {
                        label: '% Growth Profile Uplift',
                        data: [{
                            x: growth.uplifts,
                            y: count.uplifts,
                            r: 15
                        }],
                        backgroundColor: "#FE0200",
                    },
                    {
                        label: '% Growth Samphie Pack',
                        data: [{
                            x: growth.samphiePacks,
                            y: count.samphiePacks,
                            r: 15
                        }],
                        backgroundColor: "#4673C4",
                    }
                ]
            }}
            className="chart shadow-lg"
            width={500}
            height={500}
            options={{
                maintainAspectRatio: false, 
                responsive: false,
                scales: {
                    x: {
                        ticks: {
                            callback: function(value) {
                                return value + "%";
                            }
                        },
                        beginAtZero: true
                    },
                    y: {
                        beginAtZero: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                var label = context.dataset.label || '';
                                if (context.parsed.x !== null) {
                                    label = ["Percentage: " + context.parsed.x + "%", "Count: " + context.parsed.y];
                                }
                                return label;
                            }
                        }
                    }
                }
            }}
        />}
        </div>);
};

export default GrowthChart;