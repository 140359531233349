import React from "react"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
class AddInterest extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            name: "",
        }
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Add Interest</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                <input name="name" onChange={this.addInterestFormHandler.bind(this)} className="c_microforms_inputfull" placeholder="Interest"/>
                <p>{this.state.message}</p>
            </div>
            <div className="c_microforms_footer">
                <button className="c_microforms_button" onClick={this.onAddInterestClicked.bind(this)}>Add Interest</button>
            </div>
        </div>
    }
    addInterestFormHandler(event){
        var newstate = {...this.state}
        newstate.message = ""
        newstate[event.target.name] = event.target.value
        this.setState(newstate)
    }
    async onAddInterestClicked(){
        // console.log(this.state)
        try{
            var newstate = {...this.state}
            var addcategory = await axios.post(ApiHelper.primaryInterest.addInterest, {
                name: this.state.name,
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.message = addcategory.data.error_message
            this.setState(newstate)
        }catch(error){
            console.log(error)
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }    
    }
    async componentDidMount(){

    }
}
export default AddInterest