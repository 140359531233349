import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"

export default function AddCategoires(props) {
    const { onChangeHandler, categoryName, onAddCategory } = props;

    return (
        <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Add Categories</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                <input category_name="category_name" value={categoryName} onChange={onChangeHandler} className="c_microforms_inputfull" placeholder="Category" />
            </div>
            <div className="c_microforms_footer">
                <button className="c_microforms_button" onClick={onAddCategory}>Add Category</button>
            </div>
        </div>
    )
}