import React from "react"
import { withRouter } from "react-router"
import Users from "../microforms/Users"

class UsersPage extends React.Component {
    render(){
        return <div>
            <Users/>
        </div>
    }
}
export default withRouter(UsersPage)