import axios from "axios"
import React from "react"
import ApiHelper from "src/model/ApiHelper"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"
import LifeStyleCategory from "../rows/LifeStyleCategory"
class LifeStyleCategoriesList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error:false,  
            message: "",
            categories: []
        }
    }
    showLifetStyleCategories(){
        if(this.state.error)return this.state.message
        console.log(this.state.categories)
        var rows = []
        for(var i = 0; i < this.state.categories.length; i++){
            var category = this.state.categories[i]
            rows.push(<LifeStyleCategory key={category.id} header={false} serial_id={category.id} name={category.category_name} image={category.category_image} onDeleteClicked={this.onDeleteClicked.bind(this)}/>)
        }
        return rows
    }
    render(){
        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Life Style Categories List</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                {/* <AdminCredential header={true} name={<b>Name</b>} emailid={<b>Email ID</b>} authority={<b>Authority</b>}/> */}
                {this.showLifetStyleCategories()}
            </div>
            <div className="c_microforms_footer row g-0">
                <div className="col-6">Showing {this.state.offset} - {this.state.offset + this.state.categories.length}</div>
                <div className="col-6">
                    <span onClick={this.onPreviousClicked.bind(this)} className="c_microforms_horizontalpaddedtextbutton">Previous</span>
                    <span onClick={this.onNextClicked.bind(this)} className="c_microforms_horizontalpaddedtextbutton">Next</span>
                    <span onClick={this.onRefreshClicked.bind(this)} className="c_microforms_horizontalpaddedtextbutton">Refresh</span>
                </div>
            </div>
        </div>
    }
    onRefreshClicked(){
        this.getLifeStyleCategories()
    }
    async onDeleteClicked(id, name){
        console.log(id)
        var confirmation = window.confirm("Delete "+name+"? This can't be undone!")
        if(confirmation){
            this.deleteCategory(id)
        }
    }
    async onNextClicked(){
        const newstate = {...this.state}
        newstate.offset += 10
        this.setState(newstate, () => {
            this.getLifeStyleCategories()
        })
    }
    async onPreviousClicked(){
        const newstate = {...this.state}
        newstate.offset -= 10
        if(newstate.offset < 0) newstate.offset = 0
        this.setState(newstate, () => {
            this.getLifeStyleCategories()
        })
    }
    async componentDidMount(){
        await this.getLifeStyleCategories()
    }
    async deleteCategory(id){
        try{
            var newstate = {...this.state}
            await axios.post(ApiHelper.categoryScreen.removeLifeStyleCategory, {categoryid: id}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.message = ""
            this.setState(newstate)
            this.getLifeStyleCategories()
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
    async getLifeStyleCategories(){
        try{
            var newstate = {...this.state}
            var lifestylecategories = await axios.post(ApiHelper.categoryScreen.getLifeStyleCategoriesByOffset, {offset: this.state.offset}, ApiHelper.getAccessTokenHeaderConfig())
            newstate.error = false
            newstate.categories = lifestylecategories.data.data
            this.setState(newstate)
        }catch(error){
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }
}
export default LifeStyleCategoriesList