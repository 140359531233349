import axios from "axios";
import React from "react";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";
class AddAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      error: false,
      message: "",
      authorities: [],
      name: "",
      emailid: "",
      password: "",
      admintype: 1,
    };
  }
  showAuthorityLevels() {
    var rows = [];
    for (var i = 0; i < this.state.authorities.length; i++) {
      var authority = this.state.authorities[i];
      rows.push(
        <option key={i} value={authority.serial_id}>
          {authority.authority}
        </option>
      );
    }
    return rows;
  }
  render() {
    return (
      <div className="c_microforms_container">
        <div className="c_microforms_header row g-0">
          <div className="col-10 c_microforms_headertext">Add Credential</div>
          <div className="col-2 c_microforms_headerbutton"></div>
        </div>
        <div className="c_microforms_main">
          <input
            name="name"
            onChange={this.addAdminFormHandler.bind(this)}
            className="c_microforms_inputfull"
            placeholder="Full Name"
          />
          <input
            name="emailid"
            onChange={this.addAdminFormHandler.bind(this)}
            className="c_microforms_inputfull"
            placeholder="Email ID"
          />
          <input
            name="password"
            onChange={this.addAdminFormHandler.bind(this)}
            className="c_microforms_inputfull"
            placeholder="Password"
          />
          Select Admin Type (ordered highest to lowest)
          <select
            name="admintype"
            onChange={this.addAdminFormHandler.bind(this)}
            className="c_microforms_selectfull"
          >
            {this.showAuthorityLevels()}
          </select>
          <p>{this.state.message}</p>
        </div>
        <div className="c_microforms_footer">
          <button
            onClick={this.onAddAdminClicked.bind(this)}
            className="c_microforms_button"
          >
            Add Admin
          </button>
        </div>
      </div>
    );
  }
  addAdminFormHandler(event) {
    var newstate = { ...this.state };
    newstate.message = "";
    newstate[event.target.name] = event.target.value;
    this.setState(newstate);
  }
  async onAddAdminClicked() {
    console.log(this.state);
    try {
      var newstate = { ...this.state };
      var addadmin = await axios.post(
        ApiHelper.adminCredentials.addAdminCredential,
        {
          name: this.state.name,
          emailid: this.state.emailid,
          password: this.state.password,
          authority: parseInt(this.state.admintype),
        },
        ApiHelper.getAccessTokenHeaderConfig()
      );
      newstate.error = false;
      newstate.message = addadmin.data.error_message;
      this.setState(newstate);
    } catch (error) {
      newstate.error = true;
      newstate.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
  async componentDidMount() {
    this.getAuthorityLevels();
  }
  async getAuthorityLevels() {
    try {
      var newstate = { ...this.state };
      var getauthorities = await axios.post(
        ApiHelper.adminCredentials.getAuthorityLevels,
        {},
        ApiHelper.getAccessTokenHeaderConfig()
      );
      newstate.error = false;
      newstate.authorities = getauthorities.data.data;
      this.setState(newstate);
    } catch (error) {
      newstate.error = true;
      newstate.message = error.response.data.error_message;
      this.setState(newstate);
    }
  }
}
export default AddAdmin;
