import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./microforms.css"

class CategoryList extends React.Component {

    render() {

        const { categories = [], onDeleteCategory} = this.props;

        return <div className="c_microforms_container">
            <div className="c_microforms_header row g-0">
                <div className="col-10 c_microforms_headertext">Categories List</div>
                <div className="col-2 c_microforms_headerbutton"></div>
            </div>
            <div className="c_microforms_main">
                <table style={{width:"100%"}}>
                    <tbody>
                        {categories.map((item, index) => {
                            return <tr key={index}>
                                <td>{item.category_name}</td>
                                <td ><span style={{cursor: "pointer", color: "red"}} onClick={()=> onDeleteCategory(item.cat_id)} >Delete</span></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    }
}
export default CategoryList