/* eslint-disable no-useless-escape */
//jshint esversion: 9
import React, { useEffect, useState } from "react";
import axios from "axios";
import ApiHelper from "src/model/ApiHelper";
import "bootstrap/dist/css/bootstrap.min.css";
import "./microforms.css";

const ReportedProfile = ({profileId, reportId, error, suspendReportedUser, dismissUserReport}) =>  {
    const [profile, setProfile] = useState({});
    const [report, setReport] = useState({});
    const reportedProfileDiv = React.useRef(null);

    useEffect(() => {
        async function getUserDetails() {
            try{
                const reportedprofile = await axios.post(ApiHelper.userDetails.getUserDetails, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                const userMatches = await axios.post(ApiHelper.userDetails.getUserMatches, {userid: profileId}, ApiHelper.getAccessTokenHeaderConfig());
                
                var data = reportedprofile.data.data;
                var matches = userMatches.data.data;

                if(data) {
                    setProfile(data);
                }
                if(matches || matches === 0) {
                    setProfile({...data, matches});
                }
            }catch(error){
                return 0;
            }
        }

        async function getReportDetails() {
            const reportdetails = await axios.post(ApiHelper.userReports.getReportDetails, {report_id: reportId}, ApiHelper.getAccessTokenHeaderConfig());
            const report = reportdetails.data.data;
            const reporter = await axios.post(ApiHelper.userDetails.getUserName, {userid: report.user_id}, ApiHelper.getAccessTokenHeaderConfig());
            report.reporter = reporter.data.data.user_name;
            report.reasons = report.reason;
            setReport(report);
        }
        getUserDetails();
        getReportDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileId]);

    useEffect(() => {
        reportedProfileDiv.current.scrollIntoView();
    }, []);
    
    return <div className="c_microforms_container" ref={reportedProfileDiv}>
        <div className="c_microforms_header row g-0">
            <div className="col-10 c_microforms_headertext">Reported Profile</div>
            {error && <div style={{color: "red"}}>{error}</div>}
            <div className="c_microforms_main row">
                {profile.user_photo ? 
                    <img className="c_microforms_profile_picture col-2 p-0" src={profile.user_photo} alt="Profile" />
                    :
                    <span style={{color: "red"}}>This user doesn't have a profile picture.</span>
                }
                <div className="col-5 h-auto">
                    <div className="c_microforms_username">{profile.user_name}</div>
                    <div className="m-3">
                        <div><b>Total Matches:</b> {profile.matches}</div>
                        <div><b>Paid User:</b> {profile.paid_user}</div>
                        <div className="mt-3">
                            <div><b>Location:</b> {profile.user_location}</div>
                            <div><b>Email:</b> {profile.email}</div>
                            <div><b>Contact:</b> {profile.phone}</div>
                        </div>
                        <div className="mt-3">
                            <b>Report Message: </b>
                            <span>{report.message}</span>
                        </div>
                    </div>
                </div>
                <div className="col-5 my-auto">
                    <div className="mb-3">
                        <b>Reported by: </b> 
                        <span>{report.reporter}</span>
                        <br />
                        {Array.isArray(report.reasons) ?
                            <React.Fragment>
                                <b>Reasons: </b>
                                {report.reasons.map((reason, i) => {
                                    if(i !== report.reasons.length - 1) {
                                        return <span>{reason + ", "}</span>
                                    } else {
                                        return <span>{reason + "."}</span>
                                    }
                                })}
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <b>Reason: </b>
                                <span>{report.reasons}</span>
                            </React.Fragment>
                        }
                    </div>
                    <div className="mb-3 p-0 w-auto">
                    {profile.life_snaps &&
                        <React.Fragment>
                        <b>Lifesnaps: </b>
                        <br />
                            {profile.life_snaps.map(lifesnap => <img className="c_microforms_uploaded_images mb-3" src={lifesnap} alt="Lifesnap" />)}   
                        </React.Fragment>
                    }
                    <br />
                    {profile.interests &&
                        <React.Fragment>
                        <b>Interest Images: </b>
                        <br />
                            {profile.interests.map(interest => {
                                return (
                                    interest.interest &&
                                    <React.Fragment>
                                        <span>{interest.interest}:</span>
                                        <br />
                                        {interest.int_images.map(image => <img className="c_microforms_uploaded_images" src={image} alt="Interest" />)}
                                        <br />
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    }
                    </div>
                </div>
                <div className="ms-5 mt-1 mb-4 w-auto">
                    <button className="btn btn-success btn-lg px-5 me-4" onClick={() => suspendReportedUser(profileId, profile.user_name)}>Suspend</button>
                    <button className="btn btn-danger btn-lg px-5" onClick={() => dismissUserReport(reportId, profile.user_name)}>Dismiss</button>
                </div>
            </div>
        </div>
    </div>
};

export default ReportedProfile;