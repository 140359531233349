import React from "react"
import { withRouter } from "react-router"
import AddCategoires from "../microforms/AddCategoires"
import CategoryList from "../microforms/CategoryList"
import axios from "axios"
import ApiHelper from "src/model/ApiHelper"

class Categories extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            error: false,
            message: "",
            category_name: "",
            categories: []
        }
    }

    componentDidMount() {
        this.getCategories()
    }

    onChangeHandler = (event) => {
        var newstate = { ...this.state }
        newstate.message = ""
        newstate.category_name = event.target.value
        this.setState(newstate)
    }

    onAddCategory = async () => {
        // console.log(this.state)
        try {
            var newstate = { ...this.state }
            if(!newstate.category_name) return
            var addcategory = await axios.post(ApiHelper.readablecontent.createCategory, {
                category_name: this.state.category_name,
            }, ApiHelper.getAccessTokenHeaderConfig())
            newstate.message = addcategory.data.error_message
            newstate.category_name = ""
            this.setState(newstate)
            this.getCategories()
        } catch (error) {
            console.log(error)
            newstate.error = true
            newstate.message = error.response.data.error_message
            this.setState(newstate)
        }
    }


    onDeleteCategory = async (cat_id) => {
        try {
            await axios.post(ApiHelper.readablecontent.deleteCategory, {
                cat_id: cat_id
            }, ApiHelper.getAccessTokenHeaderConfig())
            this.getCategories()
        } catch (error) {
        }
    }

    getCategories = async () => {
        try {
            const categoriesResponse = await axios.post(ApiHelper.readablecontent.getAllCategories, { offset: 0 }, ApiHelper.getAccessTokenHeaderConfig())
            this.setState({ categories: categoriesResponse.data.data })
        } catch (error) {
        }
    }

    render() {
        return <div>
            <AddCategoires
                onAddCategory={this.onAddCategory}
                categoryName={this.state.category_name}
                onChangeHandler={this.onChangeHandler}
            />
            <CategoryList 
                categories={this.state.categories}
                onDeleteCategory={this.onDeleteCategory}
            />
        </div>
    }
}
export default withRouter(Categories)